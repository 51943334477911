import React , {useState, useEffect, useCallback} from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import TopNavBarLayout from "../layouts/TopNavBarLayout";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import ads from "../api/ads";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';

function UserProfile() {
    const [promozioni, setPromozioni] = useState();
    const [newPromo, setNewPromo] = useState();
    const [change, setChange] = useState({});
    const [disable, setDisable] = useState();

    const [newNome, setNewNome] = useState();

    const [modifica, setModifica] = useState([]);

    

    useEffect(() => {
        (
            async function() {
             try {
                // const ciao = await ads.getPromozioni();
                // setPromozioni(ciao);
    
             } catch (e) {
                 console.error(e);
             }
         }
        )()}, [promozioni]);    
        
        const rows = promozioni?.data?.map((row) => {
          const modi = modifica.find((f) => f.ind === row.id && f.state === true);
          return(
            <TableRow
            key={row.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.id}
            </TableCell>
            <TableCell align="left">{modi !== undefined? <TextField variant="standard" value={change.Nome}  onChange={(e) => setChange({...change, Nome: e.target.value})} ></TextField> : row.nome}</TableCell>
        
            <TableCell align="right">    {modi !== undefined ? <IconButton onClick={async () => {
      

    const m = modifica?.find((f) => f.ind === row.id); m.state = false; 

    await ads.changePromo({Id:row.id, Nome:change.Nome, });

    setDisable(false);


  }}> <SaveIcon></SaveIcon> </IconButton>:<IconButton  disabled={change.id !== row.id && disable === true}
     onClick={async() =>{
  
   
      const m = modifica?.find((f) => f.ind === row.id);
      if(m === undefined){   
      setModifica([...modifica, {ind: row.id, state: true }])

     
 }else
 {
   m.state = true;
 
 }
 setChange({Id: row.id, Nome: row.nome, Cognome: row.cognome, Citta: row.citta, Numero: row.numero, Email: row.email, Linkedin: row.linkedin, Posizione: row.posizione, Foto: row.foto, Old: row.old})
 setDisable(true);
 }} ><EditIcon /></IconButton> }</TableCell>
            <TableCell align="left"><IconButton onClick={() => ads.delPromozioni(row.id)} ><DeleteIcon /></IconButton></TableCell>
          </TableRow>
          );
        })
 
  return (
    <TopNavBarLayout>
       <div style={{padding:"20px"}}>

        <Typography variant="h4">Lista promozioni</Typography>
        <Grid
  container
  direction="row"
  justifyContent="flex-end"
  alignItems="center"
>
        <TextField value={newPromo} fullWidth onChange={ (e) => setNewPromo(e.target.value) } style={{margin:"10px", maxWidth:"80%"}} id="outlined-basic" label="Nuova promozione" variant="outlined" />
        <Button onClick={() =>{ ads.setPromo({Id:null, Nome:newPromo , NomeFile:null}); setNewPromo("")}}style={{margin:"10px"}} variant="contained">Aggiungi promozione</Button>
   </Grid>
    
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell>Id</TableCell>
            <TableCell align="left">Nome</TableCell>
            <TableCell align="right">Modifica</TableCell>
            <TableCell align="left">Cancella</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
         {rows}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
    </TopNavBarLayout>
  );
}

export default UserProfile;
