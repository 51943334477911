import axios from "axios";
import { catcher } from "./helpers";
import configuration from "../configs/config.json";

const { http } = configuration;

const client = axios.create({
  baseURL: http.baseUrl,
  headers: http.headers,
});

export default {
  cantons: async (language) => {
    const lang = language ? language.split("-")[0] : undefined;
    const endpoint = lang ? `/geo/canton.${lang}` : "/geo/canton";
    return await catcher(client.get, endpoint, { ...http.defaults }, true);
  },
  cities: async (id) => {
    const endpoint = `/geo/canton/${id}/city`;
    return await catcher(client.get, endpoint, { ...http.defaults }, true);
  },
  city: async (idCantone, idCitta) => {
    const endpoint = `/geo/canton/${idCantone}/city/${idCitta}`;
    return await catcher(client.get, endpoint, { ...http.defaults }, true);
  },
  regions: async () => {
    const endpoint = "/geo/region";
    return await catcher(client.get, endpoint, { ...http.defaults }, true);
  },
  put_region: async (name) => {
    const endpoint = "/geo/region";
    return await catcher(client.put, endpoint, { ...http.defaults, name });
  },
};
