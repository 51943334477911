import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers/reducers";
import middlewares from "./middlewares/middlewares";
import { composeWithDevTools } from "redux-devtools-extension";
import mySaga from "./sagas/saga";

// import { updateTheme } from "./middlewares/themes.middleware.js";

import { persistedState, saveState } from "./persisted.store.js";

export default function configureStore() {
  const store = createStore(
    reducers,
    persistedState, // second argument overrides the initial state
    composeWithDevTools(applyMiddleware(...middlewares)) //this middleware enables browser extension to check out the store, and passing other middlewares
  );

  // add a listener that will be invoked on any state change
  store.subscribe(() => {
    saveState(store.getState());
  });

  middlewares[2].run(mySaga);
  // console.log(middlewares)

  // Update the initial theme
  // updateTheme(store.getState());

  return store;
}
