import configuration from "../../configs/config.json";

const { validation } = configuration;

/**
 * Helper to convert between different types
 */
const convert = {
  toNumber: (text) =>
    text ? parseFloat(text.toString().replace(",", ".")) : NaN,
};

/**
 * Comparison helper
 */
const compare = {
  between: (input, min, max) => {
    if (input) {
      const value = convert.toNumber(input);
      return min <= value && value <= max;
    }
    return false;
  },
};

/**
 * Words separated by single space
 */
export const nameValidator = /^\S{3,}/i;

/**
 * https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s03.html
 * https://en.wikipedia.org/wiki/Telephone_numbering_plan
 */
export const phoneValidator = /^(00)?[0-9]{4,15}$/;

/**
 * Validate email format
 */
export const emailValidator = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Range validator
 */
export const measureValidator = {
  min: validation.measure.min,
  max: validation.measure.max,
  test: (input) =>
    compare.between(input, measureValidator.min, measureValidator.max),
};

export const numberValidator = {
  test: (input) => input !== null && !isNaN(input),
};

export const optionalValidator = (validator) => ({
  test: (input) => {
    return (
      input === undefined ||
      input === null ||
      input === "" ||
      validator.test(input)
    );
  },
});

export const adInsertValidator = {
  test: (input) => {
    const { contact, real_estate, amounts } = input;
    if(Number.isNaN(amounts.pigione)){
      amounts.pigione = 0;
    }
    return (
     
      optionalValidator(nameValidator).test(contact.nome) &&
      optionalValidator(emailValidator).test(contact.email) &&
      numberValidator.test(real_estate.contratto) &&
      numberValidator.test(real_estate.locali) &&
      numberValidator.test(real_estate.cantone) &&
      numberValidator.test(real_estate.citta) &&
      numberValidator.test(real_estate.tipologia) &&
      numberValidator.test(amounts.pigione) 
    );
  },
};
