import axios from "axios";
import _ from "lodash";
import retry from "./helpers";
import configuration from "../configs/config.json";

const { http } = configuration;

const client = axios.create({
  baseURL: http.baseUrl,
  headers: http.headers,
});

export default {
  put: async ({ id, ...rest }) => {
    return await retry(client.put, `/immobile/${id}`, {
      ...http.defaults,
      ...rest,
    });
  },
  post: async (params) => {
    return await retry(client.post, "/immobile", {
      ...http.defaults,
      ...params,
    });
  },
  upload: async (formData, options) => {
    const { doctype, ...rest } = options;
    return await retry(
      client.post,
      doctype === "image" ? "/immobile/immagine" : "/immobile/documento",
      formData,
      false,
      _.merge(rest, { headers: { "Content-Type": "multipart/form-data" } })
    );
  },
  uploadFoto: async (formData) => {
    return await retry(client.post, "/immobile/foto", formData, false);
  },
  uploadImgStabili: async (formData) => {
    console.log("xxx", formData);
    return await retry(client.post, "/immobile/fotoStabili", formData, false);
  },
  uploadImgStabiliId: async (formData, id) => {
    console.log("xxx", formData);
    return await retry(
      client.post,
      `/immobile/fotoStabili/${id}`,
      formData,
      false
    );
  },
  changeImages: async (imgs) => {
    const immaginiConverted = imgs?.map((i) => {
      const obj = {
        StabileId: i.stabileId,
        Id: i.id,
        FileName: i.fileName,
        DataUpload: i.dataUpload,
        Old: i.old,
        Descrizione: i.descrizione,
        Titolo: i.titolo,
        Posizione: i.posizione,
      };
      return obj;
    });
    return await retry(
      client.post,
      `/immobile/modImmaginiStabile`,
      immaginiConverted
    );
  },
  setFoto: async (formData, id) => {
    console.log("hh", formData);
    return await retry(client.post, `/immobile/foto/${id}`, formData, false);
  },

  unload: async (id, doctype) => {
    return await retry(
      client.delete,
      doctype === "image"
        ? `/immobile/immagine/${id}`
        : `/immobile/documento/${id}`,
      { ...http.defaults },
      true
    );
  },
  set: async (id, doctype, values) => {
    return await retry(
      client.put,
      doctype === "image"
        ? `/immobile/immagine/${id}`
        : `/immobile/documento/${id}`,
      { ...http.defaults, ...values }
    );
  },
  list: async () => {
    return await retry(client.get, "/immobile", { ...http.defaults }, true);
  },
  get: async (id) => {
    console.log("prova", id);
    return await retry(
      client.get,
      `/immobile/${id}`,
      { ...http.defaults },
      true
    );
  },
  delete: async (id) => {
    return await retry(
      client.delete,
      `/immobile/${id}`,
      { ...http.defaults },
      true
    );
  },

  setRegion: async (regione) => {
    return await retry(client.put, `/immobile/region/${regione}`, true);
  },
  getRegioni: async () => {
    return await retry(
      client.get,
      `/immobile/regioni`,
      { ...http.defaults },
      true
    );
  },
  getImmaginiStabili: async (id) => {
    return await retry(
      client.get,
      `/immobile/stabiliImmagini/${id}`,
      { ...http.defaults },
      true
    );
  },
  setStabile: async (msg) => {
    return await retry(client.post, `/immobile/stabile`, {
      Id: msg.Id,
      Nome: msg.Nome,
      Descrizione: msg.Descrizione,
      Indirizzo: msg.Indirizzo,
      Cap: msg.Cap,
      Visbilita: msg.Visibilita,
      Vetrina: msg.Vetrina,
      Citta: msg.Citta,
      Blocco: msg.Blocco,
      AnnoCostruzione: msg.AnnoCostruzione,
      NumeroAppartamenti: msg.NumeroAppartamenti,
      Codice: msg.Codice,
      RegioneId: msg.RegioneId,
      ReferenteId: msg.Referente.id,
      Video: msg.Video,
      Ordinamento: null,
      FiltroGruppo: msg.Gruppo,
      Cantone: msg.Cantone,
    });
  },
  setModStabile: async (msg) => {
    console.log("aa", msg);
    return await retry(client.post, `/immobile/modStabile`, {
      Id: msg.id,
      Nome: msg.nome,
      Descrizione: msg.descrizione,
      Indirizzo: msg.indirizzo,
      Cap: msg.cap,
      Visibilita: msg.visibilita,
      Vetrina: msg.vetrina,

      Citta: msg.citta,
      Blocco: msg.blocco,
      AnnoCostruzione: msg.annoCostruzione,
      NumeroAppartamenti: msg.numeroAppartamenti,
      Codice: msg.codice,
      RegioneId: msg.regioneId,
      ReferenteId: msg.referenteId,
      Video: msg.video,
      Ordinamento: null,
      FiltroGruppo: msg.filtroGruppo,
      Cantone: msg.cantone,
    });
  },
  getMessage: async (id) => {
    return await retry(
      client.get,
      `/immobile/messaggiclienti/${id}`,
      { ...http.defaults },
      true
    );
  },
  getLocali: async (id) => {
    return await retry(
      client.get,
      `/NumeroLocali/${id}`,
      { ...http.defaults },
      true
    );
  },

  // getPromozioni: async () => {
  //   return await retry(
  //     client.get,
  //     `/immobile/promozioni`,
  //     { ...http.defaults },
  //     true
  //   );
  // },

  getStabili: async () => {
    return await retry(
      client.get,
      `/immobile/stabili`,
      { ...http.defaults },
      true
    );
  },
  getStabile: async (id) => {
    console.log("kk", id);
    return await retry(
      client.get,
      `/immobile/stabile/${id}`,
      { ...http.defaults },
      true
    );
  },

  getRicerche: async () => {
    return await retry(
      client.get,
      `/immobile/Ricerche`,
      { ...http.defaults },
      true
    );
  },

  getMessageRef: async (id) => {
    return await retry(
      client.get,
      `/immobile/messaggireferenticlienti/${id}`,
      { ...http.defaults },
      true
    );
  },

  getClient: async (id) => {
    return await retry(
      client.get,
      `/immobile/Clienti/${id}`,
      { ...http.defaults },
      true
    );
  },

  getClients: async () => {
    return await retry(
      client.get,
      `/immobile/Clienti`,
      { ...http.defaults },
      true
    );
  },

  getRicerche: async () => {
    return await retry(
      client.get,
      `/immobile/Ricerche`,
      { ...http.defaults },
      true
    );
  },

  getImmobili: async () => {
    return await retry(
      client.get,
      `/immobile/public`,
      { ...http.defaults },
      true
    );
  },

  getReferenti: async () => {
    return await retry(
      client.get,
      `/immobile/Referenti`,
      { ...http.defaults },
      true
    );
  },

  delReferenti: async (id) => {
    return await retry(
      client.delete,
      `/immobile/Referenti/${id}`,
      { ...http.defaults },
      true
    );
  },

  delPromozioni: async (id) => {
    return await retry(
      client.delete,
      `/immobile/promozioni/${id}`,
      { ...http.defaults },
      true
    );
  },
  setMessage: async (msg) => {
    return await retry(client.post, `/immobile/AddMessage`, {
      Id: msg.Id,
      MessaggioId: msg.MessaggioId,
      Messaggio: msg.Messaggio,
      Data: msg.Data,
      ImmobileId: msg.ImmobileId,
      ReferenteId: msg.ReferenteId,
    });
  },

  changePromo: async (msg) => {
    return await retry(client.post, `/immobile/promozioni`, {
      Id: msg.Id,
      Nome: msg.Nome,
      NomeFile: null,
    });
  },

  setPromo: async (msg) => {
    console.log("aa", msg);
    return await retry(client.post, `/immobile/AddPromozione`, {
      Id: msg.Id,
      Nome: msg.Nome,
      NomeFile: null,
    });
  },

  setNewsletter: async (id, number) => {
    console.log("ss", id, number);
    return await retry(client.post, `/immobile/Newsletter/${id}/${number}`);
  },

  setReferente: async (change) => {
    return await retry(client.post, `/immobile/Referente`, {
      Id: change.Id,
      Nome: change.Nome,
      Cognome: change.Cognome,
      Citta: change.Citta,
      Numero: change.Numero,
      Email: change.Email,
      Foto: change.Foto,
      Linkedin: change.Linkedin,
      Posizione: change.Posizione,
      Old: change.Old,
    });
  },

  getVisual: async () => {
    return await retry(
      client.get,
      `/immobile/Visualizzazione`,
      { ...http.defaults },
      true
    );
  },

  getNews: async () => {
    return await retry(
      client.get,
      `/immobile/Newsletter`,
      { ...http.defaults },
      true
    );
  },
  setNewReferente: async (refer) => {
    return await retry(client.post, `/immobile/NewReferente`, {
      Id: refer.Id,
      Nome: refer.Nome,
      Cognome: refer.Cognome,
      Citta: refer.Citta,
      Numero: refer.Numero,
      Email: refer.Email,
      Foto: refer.Foto,
      Linkedin: refer.Linkedin,
      Posizione: refer.Posizione,
      Old: refer.Old,
    });
  },

  getCoordinate: async (city) => {
    return await retry(
      axios.get,
      `https://api3.geo.admin.ch/rest/services/api/SearchServer?searchText=${city}&type=locations&geometryFormat=geojson`
    );
  },

  getDistrict: async (geometry) => {
    return await retry(
      axios.get,
      `https://api3.geo.admin.ch/rest/services/api/MapServer/identify?geometryType=esriGeometryEnvelope&geometry=${geometry}&imageDisplay=500,600,96&mapExtent=548945.5,147956,549402,148103.5&tolerance=0&layers=all:ch.swisstopo.swissboundaries3d-bezirk-flaeche.fill&geometryFormat=geojson`
    );
  },
};
