import React from 'react';
import InsertionTable from '../components/InsertionTable';
import TopNavBarLayout from '../layouts/TopNavBarLayout';

function InsertionList() {
    return (
        <TopNavBarLayout>
            <InsertionTable />
        </TopNavBarLayout>
    );
}

export default InsertionList;
