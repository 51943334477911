import React from 'react'
import TopNavBarLayout from '../layouts/TopNavBarLayout'
import HomeTourUploader from '../components/HomeTourUploader'

function NotFound() {
    return (
        <TopNavBarLayout>
            <h1 style={ {textAlign: 'center', marginTop: '30vh'} }>404 Not Found.</h1>
            <HomeTourUploader />
        </TopNavBarLayout>
    );
}

export default NotFound;
