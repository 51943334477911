import axios from 'axios';
import retry from './helpers';
import configuration from '../configs/config.json';

const { http } = configuration;

const client = axios.create({
    baseURL: http.baseUrl,
    headers: http.headers,
});

export default {
    list: async () => {
        return await retry(
            client.get,
            '/referente',
            { ...http.defaults },
            true
        );
    },
    
};
