import configuration from "../../configs/config.json";

const { tables } = configuration;
const { pagination } = tables;

export const initialState = {
  loading: {
    authenticating: false,
    ads: {
      getting: false,
      putting: false,
      posting: false,
      uploading: false,
      listing: false,
      deleting: false,
    },
  },

  params: {
    ads: {
      limit: pagination.size,
      skip: 0,
      sort: { "insert.date": -1 },
    },
  },

  user: null,

  error: null,
  errors: {},

  forms: {
    formDefaultValues: {},
    login: {
      credentials: {
        username: "",
        password: "",
      },
    },
    ads: {
      items: [],
      page: 0,
      pages: 0,
      total: 0,
    },
    ad: {
      id: null,
      info: {},
      images: { images: [] },
      documents: { documents: [] },
      tour360: { tour360: [], tour360url: "" },
      portals_publishing: {
        HomeGate: false,
        ImmoScout: false,
        checked: {
          immoScout: false,
          homeGate: false,
        },
      },
      features: {
        caratteristiche: [],
      },
      vicinanze: {
        vicinanze: [],
      },
      promozioni: {
        promozioni: [],
      },
      state: {
        visibilita: false,
        note: "",
      },
      real_estate: { contratto: null, disponibilita: new Date() },
      amounts: {},
      contact: {},
    },
  },
};
