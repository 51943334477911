import _ from "lodash";

import {
  CHANGE_SORT_ORDER,
  CHANGE_PAGE,
  CHANGE_PAGESIZE,
} from "../actions/actions";

import { initialState } from "./app.initial.state.js";

const paramsReducer = (state = initialState.params, action) => {
  const { form, field, page, size } = action.payload || {};
  const clone = _.cloneDeep(state);
  switch (action.type) {
    case CHANGE_SORT_ORDER:
      const direction = clone[form]["sort"][field] || 1;
      delete clone[form]["sort"];
      clone[form]["sort"][field] = -direction;
      return clone;

    case CHANGE_PAGE:
      clone[form]["skip"] = (page - 1) * size;
      return clone;

    case CHANGE_PAGESIZE:
      clone[form]["limit"] = size;
      return clone;

    default:
      return state;
  }
};

export default paramsReducer;
