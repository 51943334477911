import config from "../../api/config";

import {
  AUTHENTICATE_SUCCESS,
  REAUTHENTICATE_SUCCESS,
  LOGOUT,
} from "../actions/actions";

import { initialState } from "./app.initial.state.js";

const clean = (state) => {
  return state && Object.keys(state).length === 0 ? null : state;
};

export const authenticateReducer = (state = initialState.user, action) => {
  const { payload } = action;
  switch (action.type) {
    case AUTHENTICATE_SUCCESS:
      config.load(payload.headers);
      return payload.data;
    case REAUTHENTICATE_SUCCESS:
      return payload.data;
    case LOGOUT:
      config.unload();
      return initialState.user;
    default:
      return clean(state);
  }
};
