import React from "react";
import { Button, Badge } from "reactstrap";
import FileUploader from "./FileUploader";
import FormInput from "./FormInput";

const FormField = ({
  fieldset,
  field,
  value,
  files,
  options,
  placeholder,
  error,
  disabled,
  onButtonClicked,
  onFilesSelected,
  onFileRemoved,
  onInputChange,
  setPrincipal,
  setPosition
}) => {
  const feedbacks = error ? [error.value] : [];

  switch (field.type) {
   
    case "button":
      return (
        <div className="text-right">
          <Button
            className="text-right"
            name={field.name}
            color="primary"
            disabled={disabled}
            onClick={(e) => onButtonClicked(e, fieldset, field)}
          >
            {field.label}
          </Button>
        </div>
      );

    case "none":
      return <span>{value}</span>;

    case "url":
      return (
        <div className="d-flex align-items-baseline">
          <div>{field.label}</div>
          {value && (
            <a className="ml-2" href={value.url}>
              {value.label}
            </a>
          )}
        </div>
      );

    case "files":
      const componentFiles = files.filter(
        (file) =>
          (file.fieldset === fieldset && file.field === field.name) ||
          file.doctype === field.doctype
      );
      return (
        <FileUploader
          {...field}
          feedbacks={feedbacks}
          files={componentFiles}
          onFileRemoved={onFileRemoved}
          setPrincipal={setPrincipal}
          setPosition={setPosition}
          onFilesSelected={(e) => onFilesSelected(e, fieldset, field.name)}
        />
      );

    case "state":
      const option = field.states
        .filter((option) => option.state === value)
        .pop();
      return (
        <div className="d-flex align-items-baseline">
          <div>{field.label}</div>
          {option && (
            <Badge className="ml-2" color={option.color}>
              {option.label}
            </Badge>
          )}
        </div>
      );

    default:
      return (
        <FormInput
          {...field}
          value={value === undefined ? "" : value}
          disabled={disabled}
          options={options}
          placeholder={placeholder}
          feedbacks={feedbacks}
          onChange={(e) =>{
          onInputChange(e, fieldset, field)}}
        />
      );
  }
};

export default FormField;
