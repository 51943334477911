import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";

const Formatter = {
  format: (value, type) => {
    switch (type) {
      case "date":
        /*
          if (value.indexOf("-") >= 0) {
            const [year, month, day] = value.split("-").map((v) => parseInt(v));
            const date = new Date(year, month - 1, day, 0, 0, 0);
            return date.toISOString();
          }
          return null;
          */
        return value && value.toISOString ? value.toISOString() : null;

      case "number":
        return parseFloat(value);

      default:
        return value;
    }
  },

  display: (value, type, forcedValue) => {
    switch (type) {
      case "date":
        return value ? new Date(value) : new Date();

      case "number":
        return isNaN(value) ? "" : parseInt(value);
      case "select":
      case "creatable-select":
        return isNaN(value) ? -1 : parseInt(value);

      case "checkbox":
      case "files":
        return value;

      case "html-editor":
        if (forcedValue) return forcedValue;
        if (!value) return EditorState.createEmpty();
        const contentBlock = htmlToDraft(value);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          return EditorState.createWithContent(contentState);
        } else {
          const content = ContentState.createFromText(value || "", "\n");
          return EditorState.createWithContent(content);
        }

      case "none":
      case "button":
      case "textarea":
      case "email":
        return value ? value.toString() : "";

      default:
        return value ? value.toString() : "";
    }
  },
};

export default Formatter;
