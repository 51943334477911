import React, { useState, useEffect, useCallback } from "react";
import ads from "../api/ads";
import TopNavBarLayout from "../layouts/TopNavBarLayout";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#058fd7",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

function Customers() {
  const [clienti, setClienti] = useState();
  const [ricerche, setRicerche] = useState();
  const [immobili, setImmobili] = useState();
  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [email, setEmail] = useState("");
  const [contratto, setContratto] = useState("");
  const [prezzo, setPrezzo] = useState("");
  const [loc, setLocali] = useState("");
  const [region, setRegione] = useState("");
  const [categ, setCategoria] = useState("");

  const locali = immobili?.data?.map((l) => l.locali);
  const uniqLocali = [
    ...new Map(locali?.map((item) => [item["numero"], item])).values(),
  ];
  const orderedLocali = uniqLocali.sort(function (a, b) {
    return a.numero - b.numero;
  });
  const listLocali = orderedLocali?.map((u) => (
    <MenuItem value={u.numero}>{u.numero}</MenuItem>
  ));

  useEffect(() => {
    (async function () {
      try {
        const ciao = await ads.getClients();
        const ciaos = await ads.getRicerche();
        const c = await ads.getImmobili();
        setClienti(ciao);
        setRicerche(ciaos);
        setImmobili(c);

        console.log("custom", ciaos);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const reg = immobili?.data?.map((l) => l.regione);
  const uniqRegione = [
    ...new Map(reg?.map((item) => [item["id"], item])).values(),
  ];
  const orderedRegione = uniqRegione.sort(function (a, b) {
    var nameA = a.nome.toUpperCase(); // ignore upper and lowercase
    var nameB = b.nome.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
  const listRegioni = orderedRegione?.map((u) => (
    <MenuItem value={u.id}>{u.nome}</MenuItem>
  ));

  const cat = immobili?.data?.map((l) => l.tipologia);
  const uniqCat = [...new Map(cat?.map((item) => [item["id"], item])).values()];

  const listCat = uniqCat?.map((u) => (
    <MenuItem value={u.id}>{u.nome}</MenuItem>
  ));

  var viewCustomer = [];
  var viewRicerche = [];
  const [ric, setric] = useState([]);
  const [cu, setcu] = useState([]);
  const listaClienti = clienti?.data?.map((row) => {
    const indexSpace = row?.cognome?.indexOf(" ");
    if (
      (row?.nome
        ?.substring(0, indexSpace)
        .toLowerCase()
        ?.includes(nome?.toLowerCase()) ||
        nome == "") &&
      (row?.cognome
        ?.substring(indexSpace)
        .toLowerCase()
        ?.includes(cognome?.toLowerCase()) ||
        cognome == "") &&
      (row?.email?.toLowerCase()?.includes(email?.toLowerCase()) ||
        email == "") &&
      (ric.includes(row.id) || ric.length == 0)
    ) {
      viewCustomer.push(row?.id);
      return (
        <StyledTableRow
          hover
          onClick={() => {
            const array = [row.id];
            setcu(array);
          }}
          key={row.id}
        >
          <StyledTableCell component="th" scope="row">
            {row?.nome?.substring(0, indexSpace)}
          </StyledTableCell>
          <StyledTableCell>
            {row?.cognome?.substring(indexSpace)}
          </StyledTableCell>
          <StyledTableCell>{row.email}</StyledTableCell>
        </StyledTableRow>
      );
    }
  });

  const listaRicerche = ricerche?.data?.map((r, index) => {
    const regione = immobili?.data?.find((i) => i.regione.id === r.regione);
    const categoria = immobili?.data?.find(
      (i) => i.tipologia.id === r.categoria
    );
    console.log("cu", r);
    if (
      (cu?.includes(r.clienteId) || cu?.length === 0) &&
      (contratto === r.contratto || contratto === "") &&
      ((prezzo <= r.prezzoMax && prezzo >= r.prezzoMin) || prezzo === "") &&
      (loc === r.locali || loc === "") &&
      (region === r.regione || region === "") &&
      (categ === r.categoria || categ === "")
    ) {
      viewRicerche.push(r.clienteId);
      return (
        <StyledTableRow
          onClick={() => {
            const array = [r.clienteId];
            setric(array);
          }}
          hover
          key={r.id}
        >
          <StyledTableCell>
            {r.contratto === 0 ? "Affitto" : "Vendita"}
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            {r.prezzoMin}
          </StyledTableCell>
          <StyledTableCell>{r.prezzoMax}</StyledTableCell>
          <StyledTableCell>{r.locali}</StyledTableCell>
          <StyledTableCell>
            {regione !== undefined && regione.length !== 0
              ? regione.regione.nome
              : ""}
          </StyledTableCell>

          <StyledTableCell>
            {categoria !== undefined && categoria.length !== 0
              ? categoria.tipologia.nome
              : ""}
          </StyledTableCell>
        </StyledTableRow>
      );
    }
  });
  console.log("custom", viewRicerche, ric);
  return (
    <TopNavBarLayout>
      <div style={{ padding: "4em" }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={5} md={4}>
            <Typography variant="h4" style={{ marginBottom: "20px" }}>
              Clienti
            </Typography>
            <FilterAltIcon style={{ fontSize: "40px", color: "#058fd7" }} />
            <TextField
              onChange={(e) => {
                setNome(e.target.value);
              }}
              id="outlined-basic"
              label="Nome"
              variant="outlined"
              style={{ margin: "10px" }}
            />

            <TextField
              onChange={(e) => setCognome(e.target.value)}
              id="outlined-basic"
              label="Cognome"
              variant="outlined"
              style={{ margin: "10px" }}
            />

            <TextField
              onChange={(e) => setEmail(e.target.value)}
              id="outlined-basic"
              label="Email"
              variant="outlined"
              style={{ margin: "10px" }}
            />
            <Button
              onClick={() => setcu(viewCustomer)}
              style={{ margin: "10px", marginLeft: "50px" }}
              variant="outlined"
            >
              Aggiorna ricerche
            </Button>
            <Button
              onClick={() => {
                const array = [];
                setric(array);
              }}
              style={{ margin: "10px", marginLeft: "50px" }}
              variant="outlined"
            >
              Vedi tutti clienti
            </Button>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Nome</StyledTableCell>
                    <StyledTableCell>Cognome</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{listaClienti}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={6} md={7}>
            <Typography variant="h4" style={{ marginBottom: "20px" }}>
              Ricerche
            </Typography>
            <FilterAltIcon style={{ fontSize: "40px", color: "#058fd7" }} />
            <FormControl>
              <InputLabel
                id="demo-simple-select-label"
                style={{ margin: "10px", backgroundColor: "#FFFFFF" }}
              >
                Contratto
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Age"
                style={{ margin: "10px", width: "200px" }}
                value={contratto}
                onChange={(e) => {
                  setContratto(e.target.value);
                }}
              >
                <MenuItem value={0}>Affitto</MenuItem>
                <MenuItem value={1}>Vendita</MenuItem>
              </Select>
            </FormControl>

            <TextField
              onChange={(e) => {
                setPrezzo(e.target.value);
              }}
              id="outlined-basic"
              label="Prezzo"
              variant="outlined"
              style={{ margin: "10px" }}
            />

            <FormControl>
              <InputLabel
                id="demo-simple-select-label"
                style={{ margin: "10px", backgroundColor: "#FFFFFF" }}
              >
                Locali
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Age"
                style={{ margin: "10px", width: "200px" }}
                value={loc}
                onChange={(e) => {
                  setLocali(e.target.value);
                }}
              >
                {listLocali}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel
                id="demo-simple-select-label"
                style={{ margin: "10px", backgroundColor: "#FFFFFF" }}
              >
                Regione
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Age"
                style={{ margin: "10px", width: "200px" }}
                value={region}
                onChange={(e) => {
                  setRegione(e.target.value);
                }}
              >
                {listRegioni}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel
                id="demo-simple-select-label"
                style={{ margin: "10px", backgroundColor: "#FFFFFF" }}
              >
                Categoria
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Age"
                style={{ margin: "10px", width: "200px" }}
                value={categ}
                onChange={(e) => {
                  setCategoria(e.target.value);
                }}
              >
                {listCat}
              </Select>
            </FormControl>
            <Button
              onClick={() => setric(viewRicerche)}
              style={{ margin: "10px", marginLeft: "50px" }}
              variant="outlined"
            >
              Aggiorna clienti
            </Button>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Contratto</StyledTableCell>
                    <StyledTableCell>Prezzo minimo</StyledTableCell>
                    <StyledTableCell>Prezzo massimo</StyledTableCell>
                    <StyledTableCell>Locali</StyledTableCell>
                    <StyledTableCell>Regione</StyledTableCell>

                    <StyledTableCell>Categoria</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{listaRicerche}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    </TopNavBarLayout>
  );
}

export default Customers;
