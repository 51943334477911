import React, { useState, useEffect } from "react";
import TopNavBarLayout from "../layouts/TopNavBarLayout";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ads from "../api/ads";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import geo from "../api/geo";
import Autocomplete from "@mui/material/Autocomplete";
import NumberFormat from "react-number-format";
import FormField from "../components/FormField";
import ThumbnailHelper from "../helpers/ThumbnailHelper";
import forms from "../configs/forms.config.json";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";

function UserProfile() {
  //Variabili
  const [promozioni, setPromozioni] = useState();
  const [cantoni, setCantoni] = useState();
  const [citta, setCitta] = useState();
  const [idCantone, setIdCantone] = useState(0);
  const [regioni, setRegioni] = useState();
  const [erroreNome, setErrorNome] = useState(false);
  const [erroreReferente, setErrorReferente] = useState(false);
  const [erroreRegione, setErrorRegione] = useState(false);
  const [erroreCantone, setErrorCantone] = useState(false);
  const [erroreCitta, setErrorCitta] = useState(false);
  const form = forms.ad;
  const [oldStabile, setOldStabile] = useState();
  const [stabile, setStabile] = useState({
    Codice: "",
    Nome: "",
    Descrizione: "",
    Regione: {},
    Cantone: "",
    Citta: "",
    Cap: "",
    Indirizzo: "",
    Blocco: "",
    Piani: "",
    NumeroAppartamenti: "",
    Referente: {},
    Video: "",
    Gruppo: "",
    Visibilita: false,
    AnnoCostruzione: "",
  });
  const [regioneSel, setRegioneSel] = useState({});
  const [cantoneSel, setCantoneSel] = useState({});
  const [cittaSel, setCittaSel] = useState({});
  const [image, setImg] = useState();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  console.log("dd", image);

  //Pubblicazione immagini

  const onPublish = (e) => {
    var fd;
    const operations = files.map((file, index) => {
      const { fieldset, field } = file;
      fd = new FormData();
      const key = `${fieldset}-${field}-${index}`;
      fd.append(key, file, file.name);
      fd.append("posizione", file.posizione);
      fd.append("id", 0);
      ads.uploadImgStabiliId(fd, oldStabile.id);
    });
  };

  // Salva immobile
  const save = async () => {
    try {
      if (oldStabile.nome === "") {
        setErrorNome(true);
      } else {
        setErrorNome(false);
      }

      if (oldStabile.referenteId === undefined) {
        setErrorReferente(true);
      } else {
        setErrorReferente(false);
      }

      if (oldStabile.regioneId === undefined) {
        setErrorRegione(true);
      } else {
        setErrorRegione(false);
      }

      if (oldStabile.cantone === "") {
        setErrorCantone(true);
      } else {
        setErrorCantone(false);
      }

      if (oldStabile.citta === "") {
        setErrorCitta(true);
      } else {
        setErrorCitta(false);
      }
      console.log("dd", oldStabile);
      await ads.setModStabile(oldStabile);
      await ads.changeImages(image);
      await onPublish();
      window.location.replace(`http://localhost:3000/stabili`);
    } catch (e) {
      console.log("Error", e);
    }
  };

  //Aggiunta file
  var [files, setFiles] = useState([]);
  const addFile = (file) => {
    setFiles((prevFiles) => {
      return [...prevFiles, file];
    });
  };
  const onButtonClicked = (e, fieldset, field) => {
    const { name } = e.target;
  };

  //Selezione file

  const onFilesSelected = (e, fieldset, field) => {
    files = Array.from(e.target.files);
    files.forEach((file) => {
      file.fieldset = fieldset;
      file.field = field;
      file.action = "upload";
      file.doctype = form[fieldset].fields.filter(
        (f) => f.name === field
      )[0].doctype;
      const params = ThumbnailHelper.createParameters(file);
      if (params.reader) {
        const reader = new FileReader();
        reader.onerror = () => {
          file.thumbnail = params.onerror;
          addFile(file);
        };
        reader.onload = () => {
          file.thumbnail = { src: reader.result };
          addFile(file);
        };
        reader.readAsDataURL(file);
      } else {
        file.thumbnail = params;
        addFile(file);
      }
    });
  };

  //Rimozione file

  const onFileRemoved = (e, file) => {
    e.preventDefault();
    e.stopPropagation();
    setFiles((prevFiles) => {
      let others = prevFiles.filter(
        (f) =>
          f.action !== "upload" ||
          f.fieldset !== file.fieldset ||
          f.field !== file.field ||
          f.name !== file.name
      );
      others = others.map((f) => {
        return f.action === "none" &&
          f.fieldset === file.fieldset &&
          f.field === file.field &&
          f.name === file.name
          ? { ...f, action: "delete" }
          : f;
      });
      return others;
    });
  };
  //Posizione immagini

  var numImage = [];
  var counter = 0;

  var numImagesArray = files.map((f) => f.posizione);

  var numImagesArray2 = image?.map((f) => {
    if (f.old === false) return f.posizione;
  });

  for (var i = 0; i < Number(files.length) + Number(image?.length); i++) {
    if (image[i]?.old === false || image[i]?.old === undefined) {
      counter = counter + 1;
      numImage.push(counter);
    }
  }

  const onInputChange = async (e, fieldset, field) => {
    let value = e;
    switch (field.type) {
      case "date":
        break;
      case "checkbox":
        value = e.target.checked;
        break;
      case "select":
      case "creatable-select":
        value = e && e.value;
        break;
      default:
        value = e.target.value;
        break;
    }
    if (field.name === "tipologia") {
      const row = {
        tipologia: { id: field.name === "tipologia" ? value : null },
      };
    }
  };

  //change posizione

  const setPosition = (file, num) => {
    setFiles((prevFiles) => {
      const newFiles = prevFiles.map((f) => {
        if (f.id !== undefined) {
          if (file.id === f.id) {
            f.posizione = num;
          }
        } else {
          if (file.name === f.name) {
            f.posizione = num;
          }
        }

        return f;
      });
      return newFiles;
    });
  };

  const setPosition2 = (file, num) => {
    const imageSelected = image?.find((el) => el.id === file.id);
    imageSelected.posizione = num;
    var newImage = [...image];
    newImage.posizione = imageSelected.posizione;

    setImg(newImage);
  };

  // lista regioni
  const defaultProps = regioni?.data?.map((option) => option.nome);

  //lista cantoni
  const defaultProps2 = cantoni?.data?.map((option) => option);

  //lista citta
  const defaultProps3 = citta?.data?.map((option) => option);

  //Download dati

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const ciaos = await ads.getReferenti();
        setPromozioni(ciaos);

        const cant = await geo.cantons();
        setCantoni(cant);

        const region = await ads.getRegioni();
        setRegioni(region);

        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get("id");
        var ciao = "";
        if (ciao === "" && oldStabile === undefined) {
          ciao = await ads.getStabile(id);
          await setOldStabile(ciao?.data[0]);
          const img = await ads.getImmaginiStabili(id);
          await setImg(img.data);
        }
        const reg = region?.data?.findIndex((object) => {
          return object.id === ciao?.data[0]?.regioneId;
        });
        setRegioneSel(reg);

        const can = cant?.data?.findIndex((object) => {
          return object.nome === ciao?.data[0]?.cantone;
        });
        setCantoneSel(can);

        const idCan = cant?.data?.find(
          (obj) => obj.nome === ciao?.data[0]?.cantone
        );

        const cit = await geo.cities(idCan?.id);
        setCitta(cit);

        const citt = cit?.data?.findIndex((object) => {
          return object.nome === ciao?.data[0]?.citta;
        });
        setCittaSel(citt);
        setLoaded(true);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    //Render

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
      />
    );
  });

  return (
    <TopNavBarLayout>
      <div style={{ padding: "20px", paddingLeft: "10%", paddingRight: "10%" }}>
        <Paper>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ backgroundColor: "#fafafa" }}
          >
            <Typography style={{ margin: "20px" }} variant="h5">
              Modifica stabile
            </Typography>
            <Button
              onClick={save}
              style={{ margin: "20px" }}
              variant="contained"
            >
              Salva
            </Button>
          </Grid>
          <Divider></Divider>
          {loading === true ? (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress
                style={{ width: "100px", height: "100px", margin: "50px" }}
                disableShrink
              />
            </Grid>
          ) : (
            <div style={{ padding: "20px" }}>
              <Typography variant="h6">Immagini</Typography>

              <Divider></Divider>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <FormField
                  key={1}
                  fieldset={"images"}
                  field={{
                    label: "Modifica immagine",
                    name: "images",
                    doctype: "image",
                    type: "files",
                    accept: "image/*",
                  }}
                  value={[]}
                  files={[]}
                  options={undefined}
                  placeholder={""}
                  error={undefined}
                  disabled={false}
                  onButtonClicked={onButtonClicked}
                  onFilesSelected={onFilesSelected}
                  onFileRemoved={onFileRemoved}
                  onInputChange={onInputChange}
                />
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {files.map((file, index) => (
                  <Paper
                    style={{
                      width: "309px",
                      backgroundColor: "#fafafa",
                      margin: "10px",
                    }}
                  >
                    <img
                      alt="immagine"
                      style={{ width: "309px" }}
                      src={file.thumbnail.src}
                    />
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      style={{ padding: "10px" }}
                    >
                      {" "}
                      <Grid item xs={12} md={12}>
                        <Typography>{file.name}</Typography>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Button
                          onClick={() => {
                            const fi = files.filter(
                              (e) => e.name !== file.name
                            );
                            setFiles(fi);
                          }}
                          style={{ marginTop: "10px", backgroundColor: "red" }}
                          variant="contained"
                        >
                          Elimina
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <TextField
                          style={{ marginTop: "20px" }}
                          id="outlined-select-currency"
                          select
                          fullWidth
                          size="small"
                          label="Posizione"
                          value={file.posizione}
                        >
                          <MenuItem
                            key={file.name}
                            value={file.posizione}
                            onClick={(e) => {
                              setPosition(file, 1000);
                            }}
                          >
                            -
                          </MenuItem>
                          {numImage.map((num) => (
                            <MenuItem
                              key={file.posizione}
                              disabled={
                                numImagesArray && numImagesArray2
                                  ? numImagesArray.includes(num) ||
                                    numImagesArray2.includes(num)
                                  : false
                              }
                              onClick={(e) => {
                                setPosition(file, parseInt(num));
                              }}
                            >
                              {num}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        {file.posizione === undefined ||
                        file.posizione === 1000 ? (
                          <></>
                        ) : (
                          <Chip
                            style={{ margin: "10px", fontSize: "20px" }}
                            label={file.posizione}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                ))}
                {image?.map((im, index) => {
                  if (im.old !== true) {
                    return (
                      <Paper
                        style={{
                          width: "309px",
                          backgroundColor: "#fafafa",
                          margin: "10px",
                        }}
                      >
                        <img
                          alt="immagine"
                          style={{ width: "309px" }}
                          src={`https://localhost:44366/img/stabili//${im.fileName}`}
                        />
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          style={{ padding: "10px" }}
                        >
                          {" "}
                          <Grid item xs={12} md={12}>
                            <Typography>{im.fileName}</Typography>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Button
                              onClick={() => {
                                var newArr = [...image];
                                im.old = true;
                                newArr[index] = im;
                                setImg(newArr);
                              }}
                              style={{
                                marginTop: "10px",
                                backgroundColor: "red",
                              }}
                              variant="contained"
                            >
                              Elimina
                            </Button>
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <TextField
                              style={{ marginTop: "20px" }}
                              id="outlined-select-currency"
                              select
                              fullWidth
                              size="small"
                              label="Posizione"
                            >
                              <MenuItem
                                key={im.fileName}
                                value={im.posizione}
                                onClick={(e) => {
                                  setPosition2(im, 1000);
                                }}
                              >
                                -
                              </MenuItem>
                              {numImage.map((num) => (
                                <MenuItem
                                  key={im.fileName}
                                  value={im.posizione}
                                  disabled={
                                    numImagesArray && numImagesArray2
                                      ? numImagesArray.includes(num) ||
                                        numImagesArray2.includes(num)
                                      : false
                                  }
                                  onClick={(e) => {
                                    setPosition2(im, parseInt(num));
                                  }}
                                >
                                  {num}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={4} md={4}>
                            {im.posizione === undefined ||
                            im.posizione === 1000 ? (
                              <></>
                            ) : (
                              <Chip
                                style={{ margin: "10px", fontSize: "20px" }}
                                label={im.posizione}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Paper>
                    );
                  }
                })}
              </Grid>
              <Typography style={{ marginTop: "30px" }} variant="h6">
                Stato
              </Typography>

              <Divider></Divider>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event) => {
                      console.log("aa", event.target.checked, oldStabile);
                      setOldStabile({
                        ...oldStabile,
                        visibilita: event.target.checked,
                      });
                    }}
                    checked={oldStabile?.visibilita}
                  />
                }
                label="Visibile"
              />
              <Typography variant="h6">Dati</Typography>
              <Divider></Divider>

              <TextField
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) =>
                  setOldStabile({ ...oldStabile, codice: e.target.value })
                }
                style={{ marginTop: "20px" }}
                size="small"
                fullWidth
                id="outlined-basic"
                label="Codice"
                variant="outlined"
                value={`${oldStabile?.codice}`}
              />
              <TextField
                onChange={(e) =>
                  setOldStabile({ ...oldStabile, nome: e.target.value })
                }
                error={erroreNome}
                helperText={erroreNome ? "Campo obbligatorio" : ""}
                style={{ marginTop: "20px" }}
                size="small"
                fullWidth
                id="outlined-basic"
                label="Nome"
                variant="outlined"
                value={`${oldStabile?.nome}`}
              />
              <TextField
                onChange={(e) =>
                  setOldStabile({ ...oldStabile, descrizione: e.target.value })
                }
                multiline
                style={{ marginTop: "20px" }}
                size="small"
                fullWidth
                id="outlined-basic"
                label="Descrizione"
                variant="outlined"
                value={`${oldStabile?.descrizione}`}
              />
              <Autocomplete
                size="small"
                id="combo-box-demo"
                fullWidth
                options={defaultProps !== undefined ? defaultProps : []}
                value={
                  defaultProps !== undefined && regioneSel !== undefined
                    ? defaultProps[regioneSel]
                    : ""
                }
                onChange={(event, newValue) => {
                  const reg = regioni?.data?.findIndex((object) => {
                    return object.nome === newValue;
                  });
                  setRegioneSel(reg);
                }}
                style={{ marginTop: "20px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={erroreRegione}
                    helperText={erroreRegione ? "Campo obbligatorio" : ""}
                    label="Regione"
                  />
                )}
              />
              <Autocomplete
                size="small"
                disablePortal
                id="combo-box-demo"
                fullWidth
                options={defaultProps2 !== undefined ? defaultProps2 : []}
                value={
                  defaultProps2 !== undefined && cantoneSel !== undefined
                    ? defaultProps2[cantoneSel]
                    : ""
                }
                onChange={async (event, newValue) => {
                  const reg = cantoni?.data?.findIndex((object) => {
                    return object.nome === newValue.nome;
                  });
                  setCantoneSel(reg);
                  const r = cantoni?.data?.find(
                    (el) => el.nome === newValue.nome
                  );
                  console.log("aa", r, r.nome, oldStabile);
                  setOldStabile({ ...oldStabile, cantone: `${r.nome}` });
                  const cit = await geo.cities(r.id);
                  setCitta(cit);
                  setCittaSel(-1);
                }}
                style={{ marginTop: "20px" }}
                getOptionLabel={(option) => option.nome}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={erroreCantone}
                    helperText={erroreCantone ? "Campo obbligatorio" : ""}
                    label="Cantone"
                  />
                )}
              />

              <Autocomplete
                size="small"
                disablePortal
                id="combo-box-demo"
                fullWidth
                options={defaultProps3 !== undefined ? defaultProps3 : []}
                onChange={(event, newValue) => {
                  const reg = citta?.data?.findIndex((object) => {
                    return object.nome === newValue.nome;
                  });
                  const r = citta?.data?.find((object) => {
                    return object.nome === newValue.nome;
                  });
                  console.log("aa", reg, r.nome, oldStabile);
                  setOldStabile({ ...oldStabile, citta: r.nome });
                  setCittaSel(reg);
                }}
                value={
                  defaultProps3 !== undefined && cittaSel !== undefined
                    ? defaultProps3[cittaSel]
                    : ""
                }
                style={{ marginTop: "20px" }}
                getOptionLabel={(option) => option.nome}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={erroreCitta}
                    helperText={erroreCitta ? "Campo obbligatorio" : ""}
                    label="Città"
                  />
                )}
              />

              <TextField
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) => (stabile.Cap = e.target.value)}
                style={{ marginTop: "20px" }}
                size="small"
                fullWidth
                id="outlined-basic"
                label="CAP"
                variant="outlined"
                value={oldStabile?.cap}
              />
              <TextField
                onChange={(e) =>
                  setOldStabile({ ...oldStabile, indirizzo: e.target.value })
                }
                style={{ marginTop: "20px" }}
                size="small"
                fullWidth
                id="outlined-basic"
                label="Indirizzo"
                variant="outlined"
                value={`${oldStabile?.indirizzo}`}
              />
              <TextField
                onChange={(e) =>
                  setOldStabile({ ...oldStabile, blocco: e.target.value })
                }
                style={{ marginTop: "20px" }}
                size="small"
                fullWidth
                id="outlined-basic"
                label="Blocco"
                variant="outlined"
                value={`${oldStabile?.blocco}`}
              />
              <TextField
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) =>
                  setOldStabile({ ...oldStabile, piani: e.target.value })
                }
                style={{ marginTop: "20px" }}
                size="small"
                fullWidth
                id="outlined-basic"
                label="Piani"
                variant="outlined"
                value={oldStabile?.piani}
              />
              <TextField
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) =>
                  setOldStabile({
                    ...oldStabile,
                    numeroAppartamenti: e.target.value,
                  })
                }
                style={{ marginTop: "20px" }}
                size="small"
                fullWidth
                id="outlined-basic"
                label="Numero appartamenti"
                variant="outlined"
                value={oldStabile?.numeroAppartamenti}
              />
              <TextField
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) =>
                  setOldStabile({
                    ...oldStabile,
                    annoCostruzione: e.target.value,
                  })
                }
                style={{ marginTop: "20px", marginBottom: "20px" }}
                size="small"
                fullWidth
                id="outlined-basic"
                label="Anno di costruzione"
                variant="outlined"
                value={oldStabile?.annoCostruzione}
              />
              <Typography variant="h6">Referente</Typography>
              <Divider></Divider>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                fullWidth
                label="Referente"
                value={Number(oldStabile?.referenteId)}
                error={erroreReferente}
                helperText={erroreReferente ? "Campo obbligatorio" : ""}
                onChange={(e) =>
                  setOldStabile({ ...oldStabile, referenteId: e.target.value })
                }
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                {promozioni?.data?.map((option) => {
                  if (option.old !== true) {
                    return (
                      <MenuItem key={option.id} value={option.id}>
                        {option.nome} {option.cognome}
                      </MenuItem>
                    );
                  }
                })}
              </TextField>
              <Typography variant="h6">Video youtube</Typography>

              <Divider></Divider>
              <TextField
                onChange={(e) =>
                  setOldStabile({ ...oldStabile, video: e.target.value })
                }
                style={{ marginTop: "20px", marginBottom: "20px" }}
                size="small"
                fullWidth
                id="outlined-basic"
                label="Link youtube"
                variant="outlined"
                value={`${oldStabile?.video}`}
              />
              <Typography variant="h6">Filtro Gruppo</Typography>
              <Divider></Divider>
              <TextField
                onChange={(e) =>
                  setOldStabile({ ...oldStabile, filtroGruppo: e.target.value })
                }
                style={{ marginTop: "20px", marginBottom: "20px" }}
                size="small"
                fullWidth
                id="outlined-basic"
                label="Gruppo"
                variant="outlined"
                value={`${oldStabile?.filtroGruppo}`}
              />
            </div>
          )}
          <Divider></Divider>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            style={{ backgroundColor: "#fafafa" }}
          >
            <Button
              onClick={save}
              style={{ margin: "20px" }}
              variant="contained"
            >
              Salva
            </Button>
          </Grid>
        </Paper>
      </div>
    </TopNavBarLayout>
  );
}

export default UserProfile;
