import _ from "lodash";

import {
  CITY_LIST_STARTED,
  CITY_LIST,
  CITY_LIST_ERROR,
  AD_DELETE_STARTED,
  AD_DELETE,
  AD_DELETE_ERROR,
  AD_GET_STARTED,
  AD_GET,
  AD_GET_ERROR,
  AD_POST_STARTED,
  AD_POST,
  AD_POST_ERROR,
  AD_LIST_STARTED,
  AD_LIST,
  AD_LIST_ERROR,
  AD_UPLOAD_STARTED,
  AD_UPLOAD,
  AD_UPLOAD_ERROR,
  AD_PUT_STARTED,
  AD_PUT,
  AD_PUT_ERROR,
  DELETE_HOMETOUR,
  DELETE_HOMETOUR_SUCCESS,
  DELETE_HOMETOUR_ERROR,
  AUTHENTICATE_STARTED,
  AUTHENTICATE_SUCCESS,
  AUTHENTICATE_FAILURE,
  REAUTHENTICATE_STARTED,
  REAUTHENTICATE_SUCCESS,
  REAUTHENTICATE_FAILURE,
  LOGOUT,
} from "../actions/actions";

import { initialState } from "./app.initial.state.js";

export const loadingReducer = (state = initialState.loading, action) => {
  const clone = _.cloneDeep(state);
  switch (action.type) {
    case LOGOUT:
      return initialState.loading;

    case AUTHENTICATE_STARTED:
    case REAUTHENTICATE_STARTED:
      clone.authenticating = true;
      return clone;
    case AUTHENTICATE_SUCCESS:
    case AUTHENTICATE_FAILURE:
    case REAUTHENTICATE_SUCCESS:
    case REAUTHENTICATE_FAILURE:
      clone.authenticating = false;
      return clone;

    case CITY_LIST_STARTED:
    case AD_GET_STARTED:
      clone.ads.getting = true;
      return clone;
    case CITY_LIST:
    case CITY_LIST_ERROR:
    case AD_GET:
    case AD_GET_ERROR:
      clone.ads.getting = false;
      return clone;

    case AD_LIST_STARTED:
      clone.ads.listing = true;
      return clone;
    case AD_LIST:
    case AD_LIST_ERROR:
      clone.ads.listing = false;
      return clone;

    case AD_PUT_STARTED:
      clone.ads.putting = true;
      return clone;
    case AD_PUT:
    case AD_PUT_ERROR:
      clone.ads.putting = false;
      return clone;

    case AD_POST_STARTED:
      clone.ads.posting = true;
      return clone;
    case AD_POST:
    case AD_POST_ERROR:
      clone.ads.posting = false;
      return clone;

    case DELETE_HOMETOUR:
    case AD_DELETE_STARTED:
      clone.ads.deleting = true;
      return clone;
    case AD_DELETE:
    case AD_DELETE_ERROR:
    case DELETE_HOMETOUR_SUCCESS:
    case DELETE_HOMETOUR_ERROR:
      clone.ads.deleting = false;
      return clone;

    case AD_UPLOAD_STARTED:
      clone.ads.uploading = true;
      return clone;
    case AD_UPLOAD:
    case AD_UPLOAD_ERROR:
      clone.ads.uploading = false;
      return clone;

    default:
      return state;
  }
};
