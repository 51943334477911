import React, { useState, useEffect, useCallback } from "react";
import TopNavBarLayout from "../layouts/TopNavBarLayout";
import TextField from "@mui/material/TextField";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryPie } from "victory";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import Ads from "../api/ads";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Button from "@mui/material/Button";
import PrintIcon from "@mui/icons-material/Print";

function Customers() {
  const queryString = require("query-string");
  // eslint-disable-next-line no-restricted-globals
  const parsed = queryString.parse(location.search);
  const [visual, setVisual] = useState();
  const id = parsed.id;
  var date = new Date();
  const [aPartireDa, setaPartireDa] = React.useState(
    new Date(date.setMonth(date.getMonth() - 1))
  );
  const [finoA, setFinoA] = React.useState(new Date());
  const [contact, setContact] = useState();
  const [news, setNews] = useState();

  function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  function dayDiff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }
  const handleChange = (newValue) => {
    setaPartireDa(newValue);
  };

  const handleChange2 = (newValue) => {
    setFinoA(newValue);
  };

  useEffect(() => {
    (async function () {
      try {
        setVisual(await Ads.getVisual());
        setContact(await Ads.getMessage(id));
        setNews(await Ads.getNews());
      } catch (e) {
        console.error(e);
      }
    })();
  }, [id]);

  const contactImmobile = contact?.data?.filter(
    (x) =>
      x.immobileId === parseInt(id) &&
      new Date(x.data).getTime() < finoA.getTime() &&
      new Date(x.data).getTime() > aPartireDa.getTime()
  );
  const visualImmobile = visual?.data?.filter(
    (x) =>
      x.idImmobile === parseInt(id) &&
      new Date(x.data).getTime() < finoA.getTime() &&
      new Date(x.data).getTime() > aPartireDa.getTime()
  );
  const newsImmobile = news?.data?.filter(
    (x) =>
      x.idImmobile === parseInt(id) &&
      new Date(x.data).getTime() < finoA.getTime() &&
      new Date(x.data).getTime() > aPartireDa.getTime()
  );
  var num = 0;
  const sommaNews = newsImmobile?.map((n) => (num = num + n.numberNews));
  const visualSito = visualImmobile?.filter(
    (x) =>
      x.tipo === "Sito" &&
      new Date(x.data).getTime() < finoA.getTime() &&
      new Date(x.data).getTime() > aPartireDa.getTime()
  );
  const numVisualSito = visualSito?.length;
  const numVisual = visualImmobile?.length;

  const percSito = (100 / numVisual) * numVisualSito;
  const percContact = (100 / numVisual) * contactImmobile?.length;
  const visualDisplay = visualImmobile?.filter(
    (x) =>
      x.tipo === "Display" &&
      new Date(x.data).getTime() < finoA.getTime() &&
      new Date(x.data).getTime() > aPartireDa.getTime()
  );
  const numVisualDisplay = visualDisplay?.length;
  const percDisplay = (100 / numVisual) * numVisualDisplay;
  const visualNewsletter = visualImmobile?.filter(
    (x) =>
      x.tipo === "Newsletter" &&
      new Date(x.data).getTime() < finoA.getTime() &&
      new Date(x.data).getTime() > aPartireDa.getTime()
  );
  const numNewsletter = visualNewsletter?.length;
  const percNewsletter = (100 / numVisual) * numNewsletter;
  const percNews =
    sommaNews !== undefined ? (100 / sommaNews[0]) * numNewsletter : 0;
  const visualEsterni = visualImmobile?.filter(
    (x) =>
      x.tipo === "Altro" &&
      new Date(x.data).getTime() < finoA.getTime() &&
      new Date(x.data).getTime() > aPartireDa.getTime()
  );
  const numEsterni = visualEsterni?.length;
  const percEsterni = (100 / numVisual) * numEsterni;
  const asseX = [];

  const barre = [];
  const barreContatti = [];
  const barreNews = [];

  //funzione per aggiungere date
  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  // lista date per asse x
  for (var i = 0; i <= dayDiff(aPartireDa, finoA); i++) {
    const datePlus = addDays(aPartireDa, i);
    asseX.push(
      `[${datePlus?.getDate()}/${datePlus?.getMonth() + 1}/${
        datePlus?.getYear() - 100
      }]`
    );
  }

  // lista dati
  for (var i = 0; i <= dayDiff(aPartireDa, finoA); i++) {
    const datePlus = addDays(aPartireDa, i);
    const visualDay = visualImmobile?.filter(
      (x) =>
        new Date(x.data)?.getDate() === datePlus?.getDate() &&
        new Date(x.data)?.getMonth() === datePlus?.getMonth() &&
        new Date(x.data)?.getYear() === datePlus?.getYear()
    );
    console.log("xx", visualDay, datePlus);
    barre.push({
      x: `[${datePlus?.getDate()}/${datePlus?.getMonth() + 1}/${
        datePlus?.getYear() - 100
      }]`,
      y: visualDay?.length,
    });
  }

  // lista dati contatti
  for (var i = 0; i <= dayDiff(aPartireDa, finoA); i++) {
    const datePlus = addDays(aPartireDa, i);
    const visualDay = contactImmobile?.filter(
      (x) =>
        new Date(x.data)?.getDate() === datePlus?.getDate() &&
        new Date(x.data)?.getMonth() === datePlus?.getMonth() &&
        new Date(x.data)?.getYear() === datePlus?.getYear()
    );
    barreContatti.push({
      x: `[${datePlus?.getDate()}/${datePlus?.getMonth() + 1}/${
        datePlus?.getYear() - 100
      }]`,
      y: visualDay?.length,
    });
  }

  // lista dati newsletter
  for (var i = 0; i <= dayDiff(aPartireDa, finoA); i++) {
    const datePlus = addDays(aPartireDa, i);
    const visualDay = newsImmobile?.filter(
      (x) =>
        new Date(x.data)?.getDate() === datePlus?.getDate() &&
        new Date(x.data)?.getMonth() === datePlus?.getMonth() &&
        new Date(x.data)?.getYear() === datePlus?.getYear()
    );
    barreNews.push({
      x: `[${datePlus?.getDate()}/${datePlus?.getMonth() + 1}/${
        datePlus?.getYear() - 100
      }]`,
      y:
        visualDay !== undefined && visualDay.length !== 0
          ? visualDay[0]?.numberNews
          : 0,
    });
  }

  console.log("vv", visualImmobile?.length);
  return (
    <TopNavBarLayout>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <div style={{ margin: "20px" }}>
          <DesktopDatePicker
            label="A partire dal"
            inputFormat="dd/MM/yyyy"
            value={aPartireDa}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
        <DesktopDatePicker
          label="Fino al"
          inputFormat="dd/MM/yyyy"
          value={finoA}
          onChange={handleChange2}
          renderInput={(params) => <TextField {...params} />}
        />
        <Button
          style={{ margin: "20px" }}
          startIcon={<PrintIcon />}
          variant="contained"
          onClick={() => window.print()}
        >
          Stampa
        </Button>
      </Grid>
      <Accordion style={{ marginTop: "70px", marginBottom: "40px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#058fd7" }}
        >
          <VisibilityIcon style={{ fontSize: "50px", color: "#FFFFFF" }} />
          <Typography
            style={{ fontSize: "30px", marginLeft: "20px", color: "#FFFFFF" }}
          >
            {" "}
            {visualImmobile?.length} visualizzazioni
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            style={{ height: "500px" }}
          >
            <div>
              <Typography style={{ fontSize: "20px" }}>
                Timeline visualizzazioni
              </Typography>
              {/* Grafico */}
              {visualImmobile?.length !== 0 ? (
                <>
                  <VictoryChart fixLabelOverlap={true} domainPadding={15}>
                    <VictoryBar data={barre} />
                    {/* Asse X tempo */}
                    <VictoryAxis
                      fixLabelOverlap
                      tickValues={asseX}
                    ></VictoryAxis>
                    {/* Asse Y visualizzazioni */}
                    <VictoryAxis dependentAxis />
                  </VictoryChart>
                </>
              ) : (
                <Typography style={{ fontSize: "15px" }}>
                  Non ci sono dati per il periodo selezionato...
                </Typography>
              )}
            </div>
            <div style={{ width: "500px" }}>
              <Typography style={{ fontSize: "20px" }}>
                Provenienza visualizzazioni
              </Typography>
              {visualImmobile?.length !== 0 ? (
                <VictoryPie
                  width={600}
                  data={[
                    {
                      x: `${Math.round(percSito * 100) / 100}% sito`,
                      y: visualSito?.length,
                    },
                    {
                      x: `${Math.round(percDisplay * 100) / 100}% display`,
                      y: visualDisplay?.length,
                    },
                    {
                      x: `${Math.round(percEsterni * 100) / 100}% altro`,
                      y: visualEsterni?.length,
                    },
                    {
                      x: `${
                        Math.round(percNewsletter * 100) / 100
                      }% newsletter`,
                      y: visualNewsletter?.length,
                    },
                  ]}
                ></VictoryPie>
              ) : (
                <Typography style={{ fontSize: "15px" }}>
                  Non ci sono dati per il periodo selezionato...
                </Typography>
              )}
            </div>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ marginTop: "40px", marginBottom: "40px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#058fd7" }}
        >
          <ContactMailIcon style={{ fontSize: "50px", color: "#FFFFFF" }} />
          <Typography
            style={{ fontSize: "30px", marginLeft: "20px", color: "#FFFFFF" }}
          >
            {" "}
            {contactImmobile?.length} prese di contatto
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            style={{ height: "500px" }}
          >
            <div>
              <Typography style={{ fontSize: "20px" }}>
                Timeline contatti
              </Typography>
              {/* Grafico */}
              {contactImmobile?.length !== 0 ? (
                <VictoryChart fixLabelOverlap={true} domainPadding={15}>
                  <VictoryBar data={barreContatti} />
                  {/* Asse X tempo */}
                  <VictoryAxis fixLabelOverlap tickValues={asseX}></VictoryAxis>
                  {/* Asse Y visualizzazioni */}
                  <VictoryAxis dependentAxis />
                </VictoryChart>
              ) : (
                <Typography style={{ fontSize: "15px" }}>
                  Non ci sono dati per il periodo selezionato...
                </Typography>
              )}
            </div>
            <div style={{ width: "500px" }}>
              <Typography style={{ fontSize: "20px" }}>
                Contatti/visualizzazioni
              </Typography>
              {contactImmobile?.length !== 0 ? (
                <VictoryPie
                  width={600}
                  data={[
                    {
                      x: `${Math.round(percContact * 100) / 100}% contatti`,
                      y: contactImmobile?.length,
                    },
                    {
                      x: `${numVisual} visualizzazioni`,
                      y: numVisual - contactImmobile?.length,
                    },
                  ]}
                ></VictoryPie>
              ) : (
                <Typography style={{ fontSize: "15px" }}>
                  Non ci sono dati per il periodo selezionato...
                </Typography>
              )}
            </div>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ marginTop: "40px", marginBottom: "40px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#058fd7" }}
        >
          <AnnouncementIcon style={{ fontSize: "50px", color: "#FFFFFF" }} />
          <Typography
            style={{ fontSize: "30px", marginLeft: "20px", color: "#FFFFFF" }}
          >
            {" "}
            {newsImmobile?.length} newsletter spedite
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <div>
              <Typography style={{ fontSize: "20px" }}>
                Timeline newsletter
              </Typography>
              {/* Grafico */}
              {newsImmobile?.length !== 0 ? (
                <VictoryChart fixLabelOverlap={true} domainPadding={15}>
                  <VictoryBar data={barreNews} />
                  {/* Asse X tempo */}
                  <VictoryAxis fixLabelOverlap tickValues={asseX}></VictoryAxis>
                  {/* Asse Y visualizzazioni */}
                  <VictoryAxis dependentAxis />
                </VictoryChart>
              ) : (
                <Typography style={{ fontSize: "15px" }}>
                  Non ci sono dati per il periodo selezionato...
                </Typography>
              )}
            </div>
            <div style={{ width: "500px" }}>
              <Typography style={{ fontSize: "20px" }}>
                Newsletter spedite
              </Typography>
              {newsImmobile?.length !== 0 ? (
                <VictoryPie
                  width={600}
                  data={[
                    {
                      x: `${
                        Math.round(percNews * 100) / 100
                      }% newsletter cliccate`,
                      y: visualNewsletter?.length,
                    },
                    {
                      x: " ",
                      y:
                        sommaNews !== undefined
                          ? sommaNews[0] - visualNewsletter?.length
                          : 0,
                    },
                  ]}
                ></VictoryPie>
              ) : (
                <Typography style={{ fontSize: "15px" }}>
                  Non ci sono dati per il periodo selezionato...
                </Typography>
              )}
            </div>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </TopNavBarLayout>
  );
}

export default Customers;
