import React from "react";
import { Button, InputGroup, InputGroupAddon, Input } from "reactstrap";

const FilterComponent = (props) => (
  <>
    <InputGroup>
      <Input
        placeholder={props.placeholder}
        value={props.filterText}
        onChange={props.onFilter}
        type={props.type}
      />
      <InputGroupAddon addonType="append">
        <Button onClick={props.clear} color="danger">
          <i className="fa fa-times"></i>
        </Button>
      </InputGroupAddon>
    </InputGroup>
  </>
);

export default FilterComponent;
