import _ from "lodash";
import ads from "../../api/ads";
import geo from "../../api/geo";
import React, { useState, useRef, useEffect } from "react";
import Geocode from "react-geocode";
import Resizer from "react-image-file-resizer";

import {
  validationError,
  validationSuccess,
  validatedFieldValue,
  cityList,
  adPost,
  adPut,
  CHANGE_FIELD_VALUE,
  FORM_SUBMIT_CREATE,
  FORM_SUBMIT_UPDATE,
} from "../actions/actions";

import {
  adInsertValidator,
  nameValidator,
  phoneValidator,
  emailValidator,
  numberValidator,
  optionalValidator,
  // measureValidator,
} from "../middlewares/validators";
import emailjs, { init } from "emailjs-com";

import forms from "../../configs/forms.config.json";

export const INVALID_NAME = "Nome troppo breve";
export const INVALID_PHONE = "Telefono non valido";
export const INVALID_EMAIL = "Email non valida";
export const MEASURES_OUT_OF_RANGE = (min, max) =>
  `Il valore deve essere compreso tra ${min} e ${max}`;
export const REQUIRED = "Campo obbligatorio";

const validationMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  const state = getState();
  const { payload } = action;
  console.log("pay", payload);
  switch (action.type) {
    case CHANGE_FIELD_VALUE:
      validateField(dispatch, payload);
      next(action);
      break;

    case FORM_SUBMIT_CREATE:
    case FORM_SUBMIT_UPDATE:
      validateForm(action.type, state, dispatch, payload);
      next(action);
      break;

    default:
      return next(action);
  }
};
init("user_gDyWIkpeggjThinUGm1My");
const sendFeedback = (templateId, variables) => {
  emailjs
    .send("service_i8lqt8l", templateId, variables)
    .then((res) => {})
    // Handle errors here however you like, or use a React error boundary
    .catch((err) =>
      console.error(
        "Oh well, you failed. Here some thoughts on the error that occured:",
        err
      )
    );
};

const buildData = (form) => {
  const data = { id: form.id };

  Object.keys(form).forEach((fieldset) => _.merge(data, form[fieldset]));

  return data;
};
const validateForm = async (type, state, dispatch, payload) => {
  Geocode.setApiKey("AIzaSyDoqKjf0F9Y2vaVUBGTqLs7JxM3PQMMp_A");
  Geocode.setLanguage("it");

  const {
    form,
    files,
    setFileProgress,
    history,
    hometourData,
    setHometourProgress,
    portalsData,
    setPortalsServerProcessing,
  } = payload;

  switch (form) {
    case "ad":
      if (adInsertValidator.test(state.forms[form])) {
        dispatch(validationSuccess(payload));
        const data = buildData(state.forms[form]);
        switch (type) {
          case FORM_SUBMIT_CREATE:
            console.log("create", setPortalsServerProcessing);
            if (data.referente === undefined) {
              data.referente = 2;
            }
            const idCantonee = data.cantone;

            const idCittaa = data.citta;
            const cittaa = await geo.city(idCantonee, idCittaa);
            const cityNamee = cittaa.data.nome;
            var caps;

            if (data.indirizzo !== undefined && data.indirizzo !== null) {
              caps = `${data.cap} ${cityNamee} ${data.indirizzo}`;
            } else {
              caps = `${data.cap} ${cityNamee}`;
            }
            var coo = await ads.getCoordinate(caps);
            if (coo == null || coo.data == null) {
              coo = await ads.getCoordinate(data.indirizzo);
            }
            var geometry;
            if (coo.data.bbox !== undefined && coo.data.bbox !== null) {
              geometry = `${coo.data.bbox[0]},${coo.data.bbox[1]},${coo.data.bbox[2]},${coo.data.bbox[3]}`;
            }
            const boundaries = await ads.getDistrict(geometry);
            const district =
              boundaries?.data?.results[boundaries?.data?.results?.length - 1]
                .properties.name;
            state.forms.reg = undefined;

            if (district === undefined) {
              state.forms.reg = "regione";
              break;
            }

            state.forms.reg = undefined;
            const id = await ads.setRegion(district);
            data.regione = id.data;
            const ricerche = await ads.getRicerche();

            const locali = await ads.getLocali(data.locali);
            const listRicerche = ricerche?.data;
            const ricercheFiltrate = listRicerche.filter(
              (i) =>
                (data.pigione >= i.prezzoMin || i.prezzoMin === 0) &&
                (data.pigione <= i.prezzoMax || i.prezzoMax === 0) &&
                (data.tipologia === i.categoria || i.categoria === 0) &&
                (data.citta === i.citta || i.citta === null) &&
                (data.regione === i.regione || i.regione === null) &&
                (locali?.data?.numero === i.locali || i.locali === 0) &&
                data.contratto === i.contratto
            );
            const templateId = "template_3btr1tr";

            ricercheFiltrate.forEach((r) => {
              sendFeedback(templateId, {
                email_cliente: r.cliente.email,
                link_annuncio: `https://multimmobiliare.ch/dettaglio?id=${data.id}&tipo=Newsletter`,
                link_un: `https://www.multimmobiliare.ch/unsubscribe?id=${r.id}`,
              });
            });

            Geocode.fromAddress(caps).then(
              (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                data.longitude = lng;
                data.latitude = lat;

                dispatch(
                  adPost({
                    data,
                    files,
                    setFileProgress,
                    history,
                    hometourData,
                    setHometourProgress,
                    portalsData,
                    setPortalsServerProcessing,
                    ricercheFiltrate,
                  })
                );
              },
              (error) => {
                console.error("ERRORE???", error);
              }
            );

            break;
          case FORM_SUBMIT_UPDATE:
            console.log("update", setPortalsServerProcessing);
            const idCantone = data.cantone;
            const idCitta = data.citta;
            const citta = await geo.city(idCantone, idCitta);
            const cityName = citta.data.nome;
            var cap;
            if (data.indirizzo !== undefined && data.indirizzo !== null) {
              cap = `${data.cap} ${cityName} ${data.indirizzo}`;
            } else {
              cap = ` ${data.cap} ${cityName}`;
            }
            var coo = await ads.getCoordinate(cap);
            var geometrys;
            if (coo.data.bbox !== null) {
              geometrys = `${coo.data.bbox[0]},${coo.data.bbox[1]},${coo.data.bbox[2]},${coo.data.bbox[3]}`;
            }
            if (geometrys !== undefined) {
              const boundariess = await ads.getDistrict(geometrys);
              const districts =
                boundariess.data.results[boundariess?.data?.results?.length - 1]
                  .properties.name;
              state.forms.reg = undefined;

              if (districts === undefined) {
                state.forms.reg = "regione";
                break;
              }

              state.forms.reg = undefined;
              const ids = await ads.setRegion(districts);

              data.regione = ids.data;
            }
            data.HomeGate = data.checked.homeGate;
            Geocode.fromAddress(cap).then(
              (response) => {
                const { lat, lng } = response?.results[0]?.geometry?.location;
                data.longitude = lng;
                data.latitude = lat;

                dispatch(
                  adPut({
                    data,
                    files,
                    setFileProgress,
                    history,
                    hometourData,
                    setHometourProgress,
                    portalsData,
                    setPortalsServerProcessing,
                  })
                );
              },
              (error) => {
                console.error(error);
              }
            );

            break;
          default:
            break;
        }
      } else {
        Object.keys(forms[form])
          .filter((fieldset) => !["id", "features"].includes(fieldset))
          .forEach((fieldset) => {
            if (forms[form][fieldset].fields !== undefined) {
              forms[form][fieldset].fields.forEach((field) => {
                validateField(dispatch, {
                  form,
                  fieldset,
                  field,
                  value: state.forms[form][fieldset][field.name],
                });
              });
            }
          });
      }
      break;

    default:
      break;
  }
};

const validateField = (dispatch, payload) => {
  const { form, fieldset, field, value } = payload;
  switch (field.name) {
    case "nome":
      if (optionalValidator(nameValidator).test(value)) {
        dispatch(validationSuccess(payload));
      } else {
        dispatch(
          validationError({ ...payload, options: { value: INVALID_NAME } })
        );
      }
      break;

    case "email":
      if (optionalValidator(emailValidator).test(value)) {
        dispatch(validationSuccess(payload));
      } else {
        dispatch(
          validationError({ ...payload, options: { value: INVALID_EMAIL } })
        );
      }
      break;

    case "phone":
      if (optionalValidator(phoneValidator).test(value)) {
        dispatch(validationSuccess(payload));
      } else {
        dispatch(
          validationError({ ...payload, options: { value: INVALID_PHONE } })
        );
      }
      break;

    case "contratto":
    case "cantone":
    case "regione":
    case "citta":
    case "locali":
    case "tipologia":
    case "metratura":
    case "pigione":
    case "referente":
      if (numberValidator.test(value)) {
        dispatch(validationSuccess(payload));
        if (field.name === "cantone") {
          dispatch(cityList(payload));
        }
      } else {
        dispatch(validationError({ ...payload, options: { value: REQUIRED } }));
      }
      break;

    default:
      dispatch(validationSuccess(payload));
      break;
  }
  dispatch(validatedFieldValue(form, fieldset, field, value));
};

export default validationMiddleware;
