import React, { useState } from 'react';
import Modal from './Modal';
import { useSelector, useDispatch } from 'react-redux';
import { PUBLISH_ON_PORTALS_VALUE_CHANGED } from '../store/actions/actions';
import { Input, Container, Row, Col, Badge, Button } from 'reactstrap';

export default function PublishOnPortals() {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);

    const homeGateStatus = useSelector(
        (state) => state.forms.ad.portals_publishing.HomeGate || ''
    );
    const immoScoutStatus = useSelector(
        (state) => state.forms.ad.portals_publishing.ImmoScout || ''
    );
    const immoScoutChecked = useSelector(
        (state) => state.forms.ad.portals_publishing.checked.immoScout || false
    );
    const homeGateChecked = useSelector(
        (state) => state.forms.ad.portals_publishing.checked.homeGate || false
    );


    return (
        <>
            <legend>Pubblica sui portali</legend>
            <div id="publishOnPortals">
                <Button
                    color="primary"
                    onClick={(e) => {
                        e.preventDefault();
                        setIsOpen(true);
                    }}
                >
                    Apri pubblicazione su portali
                </Button>
                <Modal open={isOpen} onClose={() => setIsOpen(false)}>
                    <Container fluid>
                        <Row>
                            <legend>Pubblicazione su portali</legend>
                            <Col xs={12}>
                                <Input
                                    disabled={homeGateStatus}
                                    onChange={(e) =>
                                        dispatch({
                                            type: PUBLISH_ON_PORTALS_VALUE_CHANGED,
                                            payload: {
                                                value: e.target.checked,
                                                portal: 'homegate',
                                            },
                                        })
                                    }
                                    type="checkbox"
                                    checked={homeGateChecked}
                                ></Input>
                                HomeGate{'  '}
                            </Col>
                            <Col xs={12}>
                                <Input
                                    disabled={immoScoutStatus}
                                    type="checkbox"
                                    onChange={(e) =>
                                        dispatch({
                                            type: PUBLISH_ON_PORTALS_VALUE_CHANGED,
                                            payload: {
                                                value: e.target.checked,
                                                portal: 'immoscout',
                                            },
                                        })
                                    }
                                    checked={immoScoutChecked}
                                ></Input>
                                ImmoScout{'  '}

                            </Col>
                        </Row>
                    </Container>
                </Modal>
            </div>
        </>
    );
}
