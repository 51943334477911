import _ from "lodash";

import {
  ERROR_DISPLAY,
  LOGOUT,
  VALIDATION_ERROR,
  VALIDATION_SUCCESS,
  FETCH_AD_BY_ID_SUCCESS,
  ERROR_DISMISS,
} from "../actions/actions";

import { initialState } from "./app.initial.state.js";

export const errorReducer = (state = initialState.error, action) => {
  switch (action.type) {
    case ERROR_DISPLAY:
      return action.payload;

    case ERROR_DISMISS:
    case LOGOUT:
      return initialState.error;

    default:
      return state;
  }
};

export const errorsReducer = (state = initialState.errors, action) => {
  const { form, fieldset, field, value, index, min, max } = action.errors || {};
  const clone = _.cloneDeep(state);
  switch (action.type) {
    case VALIDATION_ERROR:
      let error = null;
      if (index) {
        error = {
          [form]: {
            [fieldset]: {
              [field.name]: {
                [`index-${index}`]: { value, min, max },
              },
            },
          },
        };
      } else {
        error = {
          [form]: {
            [fieldset]: {
              [field.name]: { value, min, max },
            },
          },
        };
      }
      return _.mergeWith({}, state, error);

    case VALIDATION_SUCCESS:
      if (!fieldset) {
        if (clone[form]) {
          delete clone[form];
        }
      } else if (index) {
        if (
          clone[form] &&
          clone[form][fieldset] &&
          clone[form][fieldset][field.name] &&
          clone[form][fieldset][field.name][`index-${index}`]
        ) {
          delete clone[form][fieldset][field.name][`index-${index}`];
          if (Object.keys(clone[form][fieldset][field.name]).length === 0) {
            delete clone[form][fieldset][field.name];
            if (Object.keys(clone[form][fieldset]).length === 0) {
              delete clone[form][fieldset];
            }
          }
        }
      } else {
        if (
          clone[form] &&
          clone[form][fieldset] &&
          clone[form][fieldset][field.name]
        ) {
          delete clone[form][fieldset][field.name];
          if (Object.keys(clone[form][fieldset]).length === 0) {
            delete clone[form][fieldset];
          }
        }
      }
      return clone;

    case FETCH_AD_BY_ID_SUCCESS:
      delete clone["ad"];
      return clone;

    default:
      return state;
  }
};
