import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import FormInput from "../components/FormInput";
import * as actions from "../store/actions/actions";

function Login() {
  const credentials = useSelector((state) => state.forms.login.credentials);
  const { username, password } = credentials || {};
  const authenticating = useSelector((state) => state.loading.authenticating);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      actions.changeFieldValue(
        "login",
        "credentials",
        { name, type: name === "password" ? "password" : "text" },
        value
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(actions.authenticate(username, password));
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit(e);
    }
  };

  return (
    <Container className="m-auto">
      <Row>
        <Col sm="12" md={{ size: 6, offset: 3 }}>
          <Card>
            <CardHeader>
              <strong>Accesso riservato</strong>
            </CardHeader>
            <LoadingOverlay
              active={authenticating}
              spinner
              text="Autenticazione in corso ..."
            >
              <CardBody>
                <Form>
                  <FormInput
                    type="text"
                    label="Utente"
                    name="username"
                    value={username}
                    onChange={handleChange}
                  />
                  <FormInput
                    type="password"
                    label="Password"
                    name="password"
                    value={password}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                  />
                  <div className="text-right">
                    <Button color="primary" onClick={handleSubmit}>
                      Accedi
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </LoadingOverlay>
            <CardFooter className="text-center">
              <small className="text-muted">
                Powered by <a href="http://www.apton.ch/">Apton.ch</a>
              </small>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
