import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Ads from "../api/ads";
import DataTableExtensions from "react-data-table-component-extensions";
import FilterComponent from "./FilterComponent";
import { Container, Row, Col, Badge } from "reactstrap";
import * as actions from "../store/actions/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
// import insertions from '../_apiMocks_/insertions.json';
import { FETCH_AD_BY_ID } from "../store/actions/actions";
import TableLoader from "./TableLoader";
import createDOMPurify from "dompurify";
import { JSDOM } from "jsdom";
import forms from "../configs/forms.config.json";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import { NavLink, NavItem } from "reactstrap";
import QueryStatsIcon from "@mui/icons-material/QueryStats";

const window = new JSDOM("").window;

const hides = forms.constraints.ad.filter((c) => c.action === "hide");

const hide = (row, target) => {
  const matches = hides.filter(
    (rule) =>
      rule.targets.includes(target) &&
      rule.when.values.includes(row[rule.when.source][rule.when.field])
  );
  return matches.length > 0;
};

//Declaring table columns and custom functions
const columns = [
  {
    name: "Id",
    selector: "id",
    sortable: true,
    width: "80px",
    grow: 1,
    format: (row) => (
      <h6>
        <Badge color="primary">{row.id}</Badge>
      </h6>
    ),
  },
  {
    name: "Riferimento",
    selector: "riferimento",
    sortable: true,
    width: "240px",
    // grow: 1,
    format: (row) => (
      <h6>
        <Badge color="secondary">{row.riferimento}</Badge>
      </h6>
    ),
  },
  {
    name: "Pubbl.",
    selector: "visibilita",
    sortable: true,
    width: "80px",
    grow: 1,
    format: (row) =>
      row.visibilita ? (
        <Badge color="success" className="pubblicatoCheck">
          <i className="fa fa-check-circle"></i>
        </Badge>
      ) : (
        <Badge color="danger" className="pubblicatoCheck">
          <i className="fa fa-times-circle" />
        </Badge>
      ),
  },
  {
    name: "Display",
    selector: "publishOnDisplay",
    sortable: true,
    width: "80px",
    grow: 1,
    format: (row) =>
      row.publishOnDisplay ? (
        <Badge color="success" className="pubblicatoCheck">
          <i className="fa fa-check-circle"></i>
        </Badge>
      ) : (
        <Badge color="danger" className="pubblicatoCheck">
          <i className="fa fa-times-circle" />
        </Badge>
      ),
  },
  {
    name: "Titolo",
    selector: "titolo",
    grow: 6,
    sortable: true,
    wrap: true,
  },
  {
    name: "Contratto",
    selector: "contratto",
    sortable: true,
    // width: '100px',
    grow: 1,
    format: (row) => (
      <h6>
        <Badge color="info">{row.contratto ? "Vendita" : "Affitto"}</Badge>
      </h6>
    ),
  },
  {
    name: "Locali",
    selector: "locali.numero",
    sortable: true,
    format: (row) => (hide(row, "locali") ? "-" : row.locali.numero.toFixed(1)),
    width: "110px",
    grow: 0.3,
    right: true,
  },
  {
    name: "Piano",
    selector: "piano",
    sortable: true,
    format: (row) =>
      hide(row, "piano")
        ? "-"
        : row.piano === 0
        ? "PT"
        : row.piano === null
        ? ""
        : row.piano.toString(),
    // width: '100px',
    grow: 0.3,
    // center: true,
    right: true,
  },
  {
    name: "Prezzo",
    selector: "pigione",
    sortable: true,
    width: "160px",
    grow: 1,
    format: (row) =>
      parseFloat(row.pigione).toLocaleString("it-CH", {
        style: "currency",
        currency: "CHF",
      }),
    wrap: false,
    right: true,
  },
  {
    name: "Cantone",
    selector: (row) => (row.cantone ? row.cantone.nome : ""),
    sortable: true,
    // width: '150px',
    grow: 1,
  },
  {
    name: "Regione",
    selector: (row) => (row.regione ? row.regione.nome : ""),
    sortable: true,
    grow: 1,
  },
  {
    name: "Citta",
    selector: (row) => (row.citta ? row.citta.nome : ""),
    sortable: true,
    // width: '150px',
    grow: 1,
  },

  {
    name: "Referente",

    selector: (row) => (row.referente ? row.referente.nome : ""),
    sortable: true,
    // width: '150px',
    grow: 1,
  },
  {
    name: "Metratura",
    selector: "metratura",
    sortable: true,
    format: (row) => `${row.metratura.toString()} mq`,
    // width: '100px',
    grow: 1,
    right: true,
  },
  {
    name: "Tipologia",
    selector: "tipologia.nome",
    sortable: true,
    // width: '150px',
    grow: 1.5,
    format: (row) => (
      <Badge
        style={{
          backgroundColor: row.tipologia.colore,
          fontSize: "1em",
        }}
        pill
      >
        {row.tipologia.nome}
      </Badge>
    ),
  },
  {
    name: "Statistiche",
    selector: "tipologia.nome",
    sortable: true,
    width: "150px",
    grow: 1.5,
    format: (row) => (
      <a href={`https://admin.multimmobiliare.com/stats?id=${row.id}`}>
        <QueryStatsIcon />
      </a>
    ),
  },

  {
    name: "Informazioni Aggiuntive",
    selector: "extra_info",
    sortable: true,
    wrap: true,
  },
].filter((item) => !forms.constraints.ads.hide.includes(item.name));

const InsertionTable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [areaFilter, setAreaFilter] = useState("");
  const [idFilter, setIdFilter] = useState("");
  // const [titleFilter, setTitleFilter] = useState("");
  const [descFilter, setDescFilter] = useState("");
  const [roomsFilter, setRoomsFilter] = useState("");
  const [floorFilter, setFloorFilter] = useState("");
  const [rentFilter, setRentFilter] = useState("");
  const [isRentedFilter, setIsRentedFilter] = useState("");
  const [cityFilter, setCityFilter] = useState("");
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  //componentDidMount => fetching data on mount
  useEffect(() => {
    const fetchData = async () => {
      // setData(result.data);
      const insertions = await Ads.list();
      // console.log(insertions);
      if (insertions.error) {
        dispatch(actions.logout());
      } else {
        setData(insertions.data);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  //Filtering items based on singular filters combined
  const filteredItems = data
    ? data.filter((item) => {
        return (
          item.citta.nome &&
          item.citta.nome.toLowerCase().includes(cityFilter.toLowerCase()) &&
          item.cantone.nome &&
          item.cantone.nome.toLowerCase().includes(areaFilter.toLowerCase()) &&
          item.id &&
          item.id.toString().toLowerCase().includes(idFilter.toLowerCase()) &&
          /*
      item.titolo &&
      item.titolo.toLowerCase().includes(titleFilter.toLowerCase()) &&
      */
          item.locali.numero &&
          item.locali.numero.toFixed(1).includes(roomsFilter.toLowerCase()) &&
          item.piano !== undefined &&
          item.pigione !== undefined &&
          item.pigione
            .toString()
            .toLowerCase()
            .includes(rentFilter.toLowerCase()) &&
          item.tipologia.nome &&
          item.tipologia.nome
            .toLowerCase()
            .includes(isRentedFilter.toLowerCase())
        );
      })
    : [];

  //Declaring object to pass to DataTableExtensions component
  const tableData = {
    columns,
    data: filteredItems,
  };

  //Expand row component
  //   const expandComponent = React.useMemo((data) => {
  //       console.log(data)
  //       return <div>{'ciao'}</div>
  //   })

  //Filtering components
  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div id="adsFilters">
        <Row>
          <Col xs={12}>
            <h5>Filtri</h5>
          </Col>
          <Col>
            <FilterComponent
              placeholder={"Id"}
              onFilter={(e) => setIdFilter(e.target.value)}
              clear={() => setIdFilter("")}
              filterText={idFilter}
              type={"text"}
            />
          </Col>
          {/* 
          <Col>
            <FilterComponent
              placeholder={"Titolo"}
              onFilter={(e) => setTitleFilter(e.target.value)}
              clear={() => setTitleFilter("")}
              filterText={titleFilter}
              type={"text"}
            />
          </Col>
          */}

          <Col>
            <FilterComponent
              placeholder={"Locali"}
              onFilter={(e) => setRoomsFilter(e.target.value)}
              clear={() => setRoomsFilter("")}
              filterText={roomsFilter}
              type={"number"}
            />
          </Col>

          <Col>
            <FilterComponent
              placeholder={"Piano"}
              onFilter={(e) => setFloorFilter(e.target.value)}
              clear={() => setFloorFilter("")}
              filterText={floorFilter}
              type={"number"}
            />
          </Col>

          <Col>
            <FilterComponent
              placeholder={"Prezzo"}
              onFilter={(e) => setRentFilter(e.target.value)}
              clear={() => setRentFilter("")}
              filterText={rentFilter}
              type={"text"}
            />
          </Col>

          <Col>
            <FilterComponent
              placeholder={"Cantone"}
              onFilter={(e) => setAreaFilter(e.target.value)}
              clear={() => setAreaFilter("")}
              filterText={areaFilter}
              type={"text"}
            />
          </Col>

          <Col>
            <FilterComponent
              placeholder={"Citta"}
              onFilter={(e) => setCityFilter(e.target.value)}
              clear={() => setCityFilter("")}
              filterText={cityFilter}
              type={"text"}
            />
          </Col>

          <Col>
            <FilterComponent
              placeholder={"Tipologia"}
              onFilter={(e) => setIsRentedFilter(e.target.value)}
              clear={() => setIsRentedFilter("")}
              filterText={isRentedFilter}
              type={"text"}
            />
          </Col>
        </Row>
      </div>
    );
  }, [
    areaFilter,
    idFilter,
    floorFilter,
    isRentedFilter,
    rentFilter,
    roomsFilter,
    cityFilter,
  ]);

  return (
    <Container fluid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <a href="/ad/new">
          {" "}
          <Button
            onClick={() =>
              window.open("http://localhost:3000/ad/new", "_blank")
            }
            variant="contained"
            startIcon={<AddIcon />}
          >
            {" "}
            Nuovo immobile
          </Button>
        </a>
      </Grid>
      <Row>
        <Col xs={12} md={12}>
          <DataTableExtensions
            export={false}
            print={false}
            {...tableData}
            filterPlaceholder={"Cercare in tutti gli oggetti"}
            //cellExport={ row => ({id: row.id})} TODO: CSV Export fields & data
          >
            <DataTable
              title="Lista Oggetti"
              responsive
              highlightOnHover
              pointerOnHover
              // subHeaderAlign={'left'}
              // expandableRows
              defaultSortAsc={false}
              defaultSortField={"id"}
              progressPending={isLoading}
              columns={columns}
              data={filteredItems}
              subHeader
              subHeaderAlign={"left"}
              subHeaderComponent={subHeaderComponentMemo}
              persistTableHead
              noDataComponent={<h4>Nessun dato trovato.</h4>}
              dense
              progressComponent={<TableLoader />}
              //   expandableRows
              //   expandableRowsComponent={expandComponent}
              //   expandOnRowClicked
              //Pagination Options
              pagination={false}
              /*
              paginationPerPage={data.length}
                          paginationRowsPerPageOptions={[
                              100,
                              200,
                              300,
                              400,
                              500,
                          ]}
                          paginationComponentOptions={{
                              rowsPerPageText: 'Righe per pagina:',
                              rangeSeparatorText: 'di',
                              noRowsPerPage: false,
                              selectAllRowsItem: false,
                              selectAllRowsItemText: 'Tutte',
                          }}
              paginationServerOptions={{
                persistSelectedOnPageChange: true,
                persistSelectedOnSort: true,
              }}
                          */
              onRowDoubleClicked={(element) => {
                dispatch({
                  type: FETCH_AD_BY_ID,
                  payload: element.id,
                  history,
                });
                history.push(`/ad/${element.id}`);
              }}
              striped
            />
          </DataTableExtensions>
        </Col>
      </Row>
    </Container>
  );
};

export default InsertionTable;
