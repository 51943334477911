import React, { Component } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupButtonDropdown,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
import DatePicker from "react-datepicker";

import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import MaskedInput from "react-maskedinput";
import { Editor } from "react-draft-wysiwyg";

const months = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre",
];
const days = ["Do", "Lu", "Ma", "Me", "Gio", "Ve", "Sa"];
const locale = {
  localize: {
    month: (n) => months[n],
    day: (n) => days[n],
  },
  formatLong: {},
};

class FormInput extends Component {
  state = {
    dropdownOpen: { append: false, prepend: false },
 
  };

  toggleDropdown = (type) => {
    this.setState((prevState) => {
      const state = { ...prevState };
      state.dropdownOpen[type] = !state.dropdownOpen[type];
      console.log("ss",state.dropdownOpen)
      return state;
    });
  };

  renderAddon = (addon) => {
    const { type, content, options, selected, disabled } = addon;
    switch (content) {
      case "text":
      case "email":
      case "phone":
      case "date":
        return (
          <InputGroupAddon addonType={type}>
            <InputGroupText>{options}</InputGroupText>
          </InputGroupAddon>
        );

      case "dropdown":
        return (
          <InputGroupButtonDropdown
            addonType={type}
            isOpen={this.state.dropdownOpen[type]}
            toggle={() => this.toggleDropDown(type)}
          >
            <DropdownToggle caret>{options[selected]}</DropdownToggle>
            <DropdownMenu>
              {options.map((value, index) => (
                <DropdownItem disabled={(disabled || []).includes(index)}>
                  {value}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </InputGroupButtonDropdown>
        );

      default:
        return null;
    }
  };

  renderInput = (props) => {
    const {
      className,
      label,
      prepend,
      append,
      feedbacks,
      explanation,
      muted,
      type,
      value,
      options,
      onChange,
      children,
      disabled,
      ...rest
    } = props;

    let behavior = { value, readOnly: true };
    const styles =
      feedbacks && feedbacks.length > 0
        ? {
            control: (provided) => ({
              ...provided,
              borderColor: "red",
            }),
          }
        : {};
    switch (type) {
      case "select":
        if (onChange && !disabled) {
          const index = options.findIndex((option) => option.value === value);
          behavior =
            index >= 0
              ? { value: options[index], onChange }
              : { value: null, onChange };
        }
        return (
          <Select
            {...rest}
            {...behavior}
            options={options}
            isClearable={true}
            styles={styles}
          />
        );

      case "creatable-select":
        if (onChange && !disabled) {
          const index = options.findIndex((option) => option.value === value);
          behavior =
            index >= 0
              ? { value: options[index], onChange }
              : { value: null, onChange };
        }
        return (
          <CreatableSelect
            {...rest}
            {...behavior}
            formatCreateLabel={(inputValue) => `Crea ${label} "${inputValue}"`}
            options={options}
            isClearable={true}
            styles={styles}
          />
        );

      case "masked":
        if (onChange && !disabled)
          behavior = { value, /*defaultValue: value,*/ onChange };
        return <MaskedInput className="form-control" {...behavior} {...rest} />;

      case "custom":
        return children;

      case "date":
        return (
          <DatePicker
            selected={value || new Date()}
            onChange={onChange}
            disabled={disabled}
            locale={locale}
            dateFormat="dd/MM/yyyy"
            className="form-control"
          />
        );

      case "html-editor":
        return <Editor editorState={value} onEditorStateChange={onChange} />;

      default:
        if (onChange && !disabled)
          behavior =
            type === "checkbox"
              ? { checked: value, onChange }
              : { value, onChange };

        if (type === "textarea") behavior = { ...behavior, rows: 7, style: {} };
        return (
          <>
            <Input
              type={type}
              invalid={feedbacks && feedbacks.length > 0}
              {...behavior}
              {...rest}
            />
            {type === "checkbox" ? " " + label : ""}
          </>
        );
    }
  };

  render() {
    const {
      className,
      name,
      type,
      label,
      prepend,
      append,
      feedbacks,
      explanation,
      muted,
      disabled,
    } = this.props;
    let input = this.renderInput(this.props);
    if (prepend || append) {
      input = (
        <InputGroup>
          {prepend && this.renderAddon({ ...prepend, type: "prepend" })}
          {input}
          {append && this.renderAddon({ ...append, type: "append" })}
        </InputGroup>
      );
    }
    const check = type === "checkbox";
    return (
      <FormGroup className={className} check={check}>
        <Label
          for={check ? undefined : name}
          className={muted ? "text-muted" : ""}
        >
          {!check && label}
        </Label>
        {input}
        {!disabled &&
          feedbacks &&
          feedbacks.map((feedback, index) => {
            return (
              feedback && (
                <FormFeedback key={index} className="d-block">
                  {feedback}
                </FormFeedback>
              )
            );
          })}
        <FormText>{explanation}</FormText>
      </FormGroup>
    );
  }
}

export default FormInput;
