import { combineReducers } from "redux";

import { errorReducer, errorsReducer } from "./errors.reducers";
import { loadingReducer } from "./loading.reducers";
import paramsReducer from "./params.reducers";
import { authenticateReducer } from "./api.reducers";
import formsReducer from "./forms.reducers";

export default combineReducers({
  loading: loadingReducer,
  params: paramsReducer,
  user: authenticateReducer,
  forms: formsReducer,
  error: errorReducer,
  errors: errorsReducer,
});
