import React, { useState } from "react";
import { NavLink as RRNavLink } from "react-router-dom";
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardFooter,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../store/actions/actions";
import config from "../configs/config.json";
import PeopleIcon from "@mui/icons-material/People";
import ContactsIcon from "@mui/icons-material/Contacts";
import StarsIcon from "@mui/icons-material/Stars";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import HomeIcon from "@mui/icons-material/Home";

const TopNavBarLayout = (props) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const user = useSelector((state) => state.user);
  const error = useSelector((state) => state.error);

  const onCloseAlert = () => {
    dispatch(actions.dismissError());
  };

  const logout = (e) => {
    e.preventDefault();
    dispatch(actions.logout());
  };

  const alert = error && (
    <Container fluid className="mt-3">
      <Row>
        <Col md={{ offset: 1, size: 10 }} sm={12}>
          <Card>
            <CardFooter>
              <Alert
                className="my-2"
                color="danger"
                isOpen={!!error}
                toggle={onCloseAlert}
                fade={true}
              >
                {error.message}
              </Alert>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </Container>
  );

  return (
    <>
      <header className="header">
        <Navbar light expand="md">
          <NavbarBrand
            href="http://www.fideconto.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {config.app.name}
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem className="mx-3">
                <NavLink tag={RRNavLink} exact to="/" activeClassName="active">
                  <HomeIcon /> Oggetti
                </NavLink>
              </NavItem>
              {/* <NavItem className="mx-3">
                <NavLink
                  tag={RRNavLink}
                  exact
                  to="/stabili"
                  activeClassName="active"
                >
                  <MapsHomeWorkIcon /> Stabili
                </NavLink>
              </NavItem>  */}

              <NavItem className="mx-3">
                <NavLink
                  tag={RRNavLink}
                  exact
                  to="/profile"
                  activeClassName="active"
                >
                  <i className="fa fa-user-circle-o"></i> Profilo
                </NavLink>
              </NavItem>
              <NavItem className="mx-3">
                <NavLink
                  tag={RRNavLink}
                  exact
                  to="/customers"
                  activeClassName="active"
                >
                  <PeopleIcon /> Clienti
                </NavLink>
              </NavItem>
              <NavItem className="mx-3">
                <NavLink
                  tag={RRNavLink}
                  exact
                  to="/referenti"
                  activeClassName="active"
                >
                  <ContactsIcon /> Referenti
                </NavLink>
              </NavItem>
              {/* <NavItem className="mx-3"> */}
              {/* <NavLink
                  tag={RRNavLink}
                  exact
                  to="/promozioni"
                  activeClassName="active"
                >
                  <StarsIcon /> Promozioni
                </NavLink> */}
              {/* </NavItem> */}
              <NavItem className="mx-3">
                <NavLink tag="a" href="/" onClick={logout}>
                  <i className="fa fa-sign-out"></i> Logout
                </NavLink>
              </NavItem>
            </Nav>
            <NavbarText>
              <i className="fa fa-user"></i> {user && user.displayName}
            </NavbarText>
          </Collapse>
        </Navbar>
      </header>
      <section className="py-4">
        {alert}
        {props.children}
        {alert}
      </section>
      <footer className="footer">
        <div className="text-center">
          <small>
            Powered by <a href="http://www.apton.ch/">Apton.ch</a>
          </small>
        </div>
      </footer>
    </>
  );
};

export default TopNavBarLayout;
