import React, { useState } from "react";
import { Row, Col, Input, Label, Badge, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../store/actions/actions";

function CaratteristicheSection({ legend }) {
  const dispatch = useDispatch();
  const features = useSelector(
    (state) => state.forms.formDefaultValues.features || []
  );
  const addedFeatures = useSelector(
    (state) => state.forms.ad.features.caratteristiche || []
  );
  const [id, setId] = useState("");
  const [featureDetail, setFeatureDetail] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");

  //Actions
  const { addFeatureToFormList, removeFeatureFromList } = actions;

  //Handling add to list button
  const handleClick = (e, id) => {
    e.preventDefault();
    let obj = features.find((o) => o.id === Number(id));
    if (obj && id !== 0) {
      const duplicateAddedFeaturesIndex = addedFeatures.findIndex(
        (o) => o.caratteristicaId === Number(id)
      );
      if (duplicateAddedFeaturesIndex !== -1) {
        dispatch({
          type: removeFeatureFromList,
          payload: duplicateAddedFeaturesIndex,
        });
        dispatch({
          type: addFeatureToFormList,
          payload: {
            caratteristicaId: obj.id,
            caratteristica: { ...obj },
            dettagli: featureDetail,
            prezzo: price,
            quantita: quantity,
          },
        });
      } else {
        dispatch({
          type: addFeatureToFormList,
          payload: {
            caratteristicaId: obj.id,
            caratteristica: { ...obj },
            dettagli: featureDetail,
            prezzo: price,
            quantita: quantity,
          },
        });
        setFeatureDetail("");
      }
    }
  };

  //Handling select value change
  const handleChange = (e) => {
    if (!(e.target.value === 0)) {
      setId(e.target.value);
    }
  };

  //Handling notes text input change
  const handleInputChange = (e) => {
    e.preventDefault();
    setFeatureDetail(e.target.value);
  };
  const inputQuantityChange = (e) => {
    e.preventDefault();
    setQuantity(e.target.value);
  };
  const inputPriceChange = (e) => {
    e.preventDefault();
    setPrice(e.target.value);
  };

  //Handling delete from list
  const handleFeatureDelete = (e, payload) => {
    e.preventDefault();
    dispatch({ type: removeFeatureFromList, payload });
  };

  return (
    <fieldset className="mb-4">
      <legend>{legend}</legend>
      <Row>
        <Col xs={2}>
          <Label>Caratteristica</Label>
          <Input
            type="select"
            id="featureSelect"
            name="featureSelect"
            onInput={handleChange}
          >
            <option value={0}>--</option>
            {features.map((el) => (
              <option key={el.id} value={el.id}>
                {el.nome}
              </option>
            ))}
          </Input>
        </Col>
        <Col sm={2}>
          <Label>Dettaglio</Label>
          <Input
            type="text"
            name="featuresNotes"
            id="featuresNotes"
            value={featureDetail}
            onChange={handleInputChange}
          />
        </Col>
        {window.location.href.indexOf("fideconto") > -1 ? (
          <></>
        ) : (
          <>
            <Col sm={2}>
              <Label>Quantità</Label>
              <Input
                type="text"
                name="featuresNotes"
                id="featuresNotes"
                value={quantity}
                onChange={inputQuantityChange}
              />
            </Col>
            {id === "3" || id === "10" || id === "18" || id === "31" ? (
              <Col sm={2}>
                <Label>Prezzo singolo</Label>
                <Input
                  type="text"
                  name="featuresNotes"
                  id="featuresNotes"
                  value={price}
                  onChange={inputPriceChange}
                />
              </Col>
            ) : (
              <></>
            )}
          </>
        )}
        <Col>
          {addedFeatures.length > 0
            ? addedFeatures.map((el, index) => {
                return (
                  <div key={el.caratteristica.id} className="features">
                    <Badge color="primary">
                      {el.quantita ? `${el.quantita} ` : ""}
                      {` ${el.caratteristica.nome}${
                        el.dettagli !== "" && el.dettagli
                          ? `: ${el.dettagli}`
                          : ""
                      }`}
                      {el.prezzo ? ` ${el.prezzo}.-` : ""}
                      <i
                        onClick={(e) => handleFeatureDelete(e, index)}
                        className="fa fa-times"
                      />
                    </Badge>
                  </div>
                );
              })
            : null}
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={2}>
          <Button onClick={(e) => handleClick(e, id)} color="primary">
            Aggiungi
          </Button>
        </Col>
      </Row>
    </fieldset>
  );
}

export default CaratteristicheSection;
