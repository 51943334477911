import React, {useState, useEffect, useCallback} from "react";
import ads from "../api/ads";
import { useSelector, useDispatch } from "react-redux";
import TopNavBarLayout from "../layouts/TopNavBarLayout";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import LinearProgress from '@mui/material/LinearProgress';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import * as actions from "../store/actions/actions";
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ModeIcon from '@mui/icons-material/Mode';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormField from "../components/FormField";
import ThumbnailHelper from "../helpers/ThumbnailHelper";
import forms from "../configs/forms.config.json";
import Ads from "../api/ads";
import FileUploadIcon from '@mui/icons-material/FileUpload';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#058fd7",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
  
  }));



function Customers() {

  const [selectedFiles, setSelectedFiles] = useState([]);

  
  const onFileChange = (e,accept, callback) => {
    
    // Create an input element
    var inputElement = document.createElement("input");

    // Set its type to file
    inputElement.type = "file";

    // Set accept to the file types you want the user to select. 
    // Include both the file extension and the mime type
    inputElement.accept = accept;

    // set onchange event to call callback when user has selected file
    inputElement.addEventListener("change", callback)

    // dispatch a click event to open the file dialog
    inputElement.dispatchEvent(new MouseEvent("click")); 

    console.log("ii",callback);
  };
  
const [referenti, setReferenti] = useState();
const [immobili, setImmobili] = useState();
const [nome, searchNome] = useState("");
const [cognome, searchCognome] = useState("");
const [email, searchEmail] = useState("");
const [disable, setDisable] = useState();
const [modifica, setModifica] = useState([]);
const [newName, setNewName] = useState("");
const [newCognome, setNewCognome] = useState("");
const [newCitta, setNewCitta] = useState("");
const [newNumero, setNewNumero] = useState("");
const [newEmail, setNewEmail] = useState("");
const [newLinkedin, setNewLinkedin] = useState("");
const [newPosizione, setNewPosizione] = useState("");
const [newFoto, setNewFoto] = useState("");
const [openDialog, setOpen] = useState(false);
const [uploading, setUploading] = useState(false);
const [inError, setInError] = useState({nome: false, cognome: false, email: false});

   useEffect(() => {
   (
       async function() {
        try {
           const ciao = await ads.getReferenti();
           setReferenti(ciao);

 
        } catch (e) {
            console.error(e);
        }
    }
   )()}, [referenti]);      


   const onButtonClicked = (e, fieldset, field) => {
    const { name } = e.target;
  };
  
const [files, setFiles] = useState([]);
const onFileRemoved = (e, file) => {
  e.preventDefault();
  e.stopPropagation();
  setFiles((prevFiles) => {

    let others = prevFiles.filter(
      (f) =>
        f.action !== "upload" ||
        f.fieldset !== file.fieldset ||
        f.field !== file.field ||
        f.name !== file.name
    );
    others = others.map((f) => {
      return f.action === "none" &&
        f.fieldset === file.fieldset &&
        f.field === file.field &&
        f.name === file.name
        ? { ...f, action: "delete"}
        : f;
    });   
    return others;
  });
};

const setPrincipal = (file) => {
  setFiles((prevFiles) => {
    const newFiles = prevFiles.map((f) => {
      f.principal = file.id === f.id;
      if (f.principal) {
        f.prevAction = f.action;
        if (f.action !== "upload") {
          f.action = "set";
        }
      } else {
        if (f.prevAction) {
          f.action = f.prevAction;
          delete f.prevAction;
        } else {
          f.action = "none";
        }
      }
      return f;
    });
    return newFiles;
  });
};

const setPosition = (file, num) => {
  setFiles((prevFiles) => {
    console.log("prev", prevFiles, file)
    const newFiles = prevFiles.map((f) => {
      if(f.id !== undefined){
      if(file.id == f.id){
        f.posizione = num;
      }
    }else{
      if(file.lastModified == f.lastModified){
        f.posizione = num;
      }

    }
   
      return f;
    });
    return newFiles;
  });
};

const addFile = (file) => {
  setFiles((prevFiles) => {
    return [...prevFiles, file];
  });
};

const form = forms.ad;

const onPublish = (e) => {
var fd;
  const operations = files
  .map((file, index) => {
        const { fieldset, field } = file;
         fd = new FormData();
        const key = `${fieldset}-${field}-${index}`;
        fd.append(key, file, file.name);
        fd.append("posizione", file.posizione);

    
  })
  Ads.uploadFoto(fd);
  
}

const onPublishMod = (id) => {
  var fd;
    const operations = files
    .map((file, index) => {
          const { fieldset, field } = file;
           fd = new FormData();
          const key = `${fieldset}-${field}-${index}`;
          fd.append(key, file, file.name);
          fd.append("posizione", file.posizione);
  
      
    })
    Ads.setFoto(fd, id);
    
  }



const onFilesSelected = (e, fieldset, field) => {
  const files = Array.from(e.target.files);
  files.forEach((file) => {
    file.fieldset = fieldset;
    file.field = field;
    file.action = "upload";
    file.doctype = form[fieldset].fields.filter(
      (f) => f.name === field
    )[0].doctype;
    const params = ThumbnailHelper.createParameters(file);
    if (params.reader) {
      const reader = new FileReader();
      reader.onerror = () => {
        file.thumbnail = params.onerror;
        addFile(file);
      };
      reader.onload = () => {
        file.thumbnail = { src: reader.result };
        addFile(file);
      };
      reader.readAsDataURL(file);
    } else {
      file.thumbnail = params;
      addFile(file);
    }
  });
};

const onInputChange = async (e, fieldset, field) => {
  let value = e;
  switch (field.type) {
    case "date":
      break;
    case "checkbox":
      value = e.target.checked;
      break;
    case "select":
    case "creatable-select":
      value = e && e.value;
      break;
    default:
      value = e.target.value;
      break;
  }
  if(field.name === "tipologia"){
   const row = {
     tipologia: { id: field.name === "tipologia" ? value : null },
   };

  }
 };

  var viewCustomer = []
  const [ric, setric] = useState([]);
  const [cu, setcu] = useState([]);
  const [change, setChange] = useState({});
const listaClienti = referenti?.data?.map((row, index) => {
  if((row?.nome?.toLowerCase()?.includes(nome?.toLowerCase()) || nome=="")
   && (row?.cognome?.toLowerCase()?.includes(cognome?.toLowerCase()) || cognome=="" )
  &&( row?.email?.toLowerCase()?.includes(email?.toLowerCase()) || email=="")
  &&( row?.citta?.toLowerCase()?.includes(newCitta?.toLowerCase()) || newCitta =="")
  &&( row?.numero?.toLowerCase()?.includes(newNumero?.toLowerCase()) || newNumero =="")
  &&( row?.posizione?.toLowerCase()?.includes(newPosizione?.toLowerCase()) || newPosizione =="")
 && (ric.includes(row.id) || ric.length == 0) && row.old === false){

   viewCustomer.push(row?.id);

   const modi = modifica.find((f) => f.ind === row.id && f.state === true);
      return(
  <StyledTableRow hover  onClick={ () => {
    const array = [row.id];
    setcu(array)}} key={row.id}>
    <StyledTableCell component="th" scope="row">
      {modi !== undefined ? <TextField id="standard-basic"  onChange={(e) => setChange({...change, Nome: e.target.value}) } variant="standard" value={change.Nome}></TextField> : row.nome}
    </StyledTableCell>
    <StyledTableCell >{modi !== undefined ? <TextField id="standard-basic"  onChange={(e) => setChange({...change, Cognome: e.target.value})} variant="standard" value={change.Cognome}></TextField> :  row.cognome}</StyledTableCell>
    <StyledTableCell >{modi !== undefined ? <TextField id="standard-basic"  onChange={(e) => setChange({...change, Citta: e.target.value}) } variant="standard" value={change.Citta}></TextField> : row.citta}</StyledTableCell>
    <StyledTableCell >{modi !== undefined ? <TextField id="standard-basic" variant="standard"   onChange={(e) => setChange({...change, Numero: e.target.value}) } value={change.Numero}></TextField>: row.numero}</StyledTableCell>
    <StyledTableCell >{modi !== undefined ? <TextField id="standard-basic"  onChange={(e) => setChange({...change, Email: e.target.value}) } variant="standard" value={change.Email}></TextField> : row.email}</StyledTableCell>
    <StyledTableCell >
      <img alt={row.foto} src={`https://api.multimmobiliare.com/img/referenti/${row.foto}`} style={{maxWidth:'100px'}} />
      {modi !== undefined ?
      <FormField
        key={1}
        fieldset={"images"}
        field={{label:"Modifica immagine",name: 'images', doctype: 'image', type: 'files', accept: 'image/*'}}
        value={[]}
        files={[]}
        options={undefined}
        placeholder={""}
        error={undefined}
        disabled={false}
        onButtonClicked={onButtonClicked}
        onFilesSelected={onFilesSelected}
        onFileRemoved={onFileRemoved}
        setPrincipal={setPrincipal}
        onInputChange={onInputChange}
        setPosition={setPosition}
      /> : <></> }</StyledTableCell>
    <StyledTableCell >{modi !== undefined ? <TextField id="standard-basic"  onChange={(e) => setChange({...change, Linkedin: e.target.value}) } variant="standard" value={change.Linkedin}></TextField> : row.linkedin !== ""? <CheckIcon style={{color:'green'}} /> :  <CloseIcon style={{color:'red'}} />}</StyledTableCell>
    <StyledTableCell >{modi !== undefined ? <TextField id="standard-basic" onChange={(e) => setChange({...change, Posizione: e.target.value}) } variant="standard" value={change.Posizione}></TextField> : row.posizione}</StyledTableCell>
    <StyledTableCell >{modi !== undefined ? <IconButton onClick={async () => {
        await onPublishMod(row.id); 
      const m = modifica?.find((f) => f.ind === row.id); m.state = false; 
    await ads.setReferente(change);

    setDisable(false);
    const ciao = await ads.getReferenti();
    setReferenti(ciao);

  }}> <SaveIcon></SaveIcon> </IconButton>:
     <IconButton
     disabled={change.id !== row.id && disable === true}
     onClick={async() =>{
  
   
      const m = modifica?.find((f) => f.ind === row.id);
      if(m === undefined){   
      setModifica([...modifica, {ind: row.id, state: true }])

     
 }else
 {
   m.state = true;
 
 }
 setChange({Id: row.id, Nome: row.nome, Cognome: row.cognome, Citta: row.citta, Numero: row.numero, Email: row.email, Linkedin: row.linkedin, Posizione: row.posizione, Foto: row.foto, Old: row.old})
 setDisable(true);
 }}>
 <ModeIcon /></IconButton>}</StyledTableCell>
    <StyledTableCell ><IconButton onClick={async () =>{ await ads.delReferenti(row.id)
     const ciao = await ads.getReferenti();
     setReferenti(ciao);
    }}><DeleteIcon /></IconButton></StyledTableCell>
  </StyledTableRow> );
  }
})




  return (
    <TopNavBarLayout >
<div style={{padding:'4em'}}>
<Grid
  container
  direction="row"
  justifyContent="space-between"
  alignItems="flex-start"
>

    <Typography variant="h4" style={{marginBottom:'20px'}}>Referenti</Typography>
    <FilterAltIcon style={{fontSize:'40px', color:"#058fd7"}} />
    <TextField onChange={(e) =>
      { searchNome(e.target.value);}} id="outlined-basic" label="Nome" variant="outlined" style={{margin:'10px'}} />

    <TextField onChange={(e) => searchCognome(e.target.value)} id="outlined-basic" label="Cognome" variant="outlined" style={{margin:'10px'}} />

    <TextField onChange={(e) => searchEmail(e.target.value)} id="outlined-basic" label="Email" variant="outlined" style={{margin:'10px'}} />
    <TextField onChange={(e) => setNewCitta(e.target.value)} id="outlined-basic" label="Citta" variant="outlined" style={{margin:'10px'}} />
    <TextField onChange={(e) => setNewNumero(e.target.value)} id="outlined-basic" label="Numero" variant="outlined" style={{margin:'10px'}} />
    <TextField onChange={(e) => setNewPosizione(e.target.value)} id="outlined-basic" label="Posizione" variant="outlined" style={{margin:'10px'}} />
    <Button onClick={() => setOpen(true)}style={{margin:'10px', marginLeft:'50px'}} variant="outlined">Aggiungi referente</Button>
<TableContainer  component={Paper}>
    

      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Nome</StyledTableCell>
            <StyledTableCell >Cognome</StyledTableCell>
            <StyledTableCell >Citta</StyledTableCell>
            <StyledTableCell >Numero</StyledTableCell>
            <StyledTableCell >Email</StyledTableCell>
            <StyledTableCell >Foto</StyledTableCell>
            <StyledTableCell >Linkedin</StyledTableCell>
            <StyledTableCell >Posizione</StyledTableCell>
            <StyledTableCell ></StyledTableCell>
            <StyledTableCell ></StyledTableCell>

          </TableRow>
        </TableHead>
        <TableBody>
         {listaClienti}
        </TableBody>
      </Table>
    </TableContainer>

        
  
    </Grid>
    </div> 
    {/* Dialog per l'aggiunta di un referente */}
    <React.Fragment>
  
      <Dialog
    
        open={openDialog}

      >
        <DialogTitle>Nuovo referente</DialogTitle>
        <DialogContent>
         <TextField error={inError.nome} helperText={inError.nome === true? "Il campo non può essere lasciato vuoto" : ""} onChange={(e) => setNewName(e.target.value)} style={{marginLeft:"30px", marginRight:"30px"}} variant="outlined" label="Nome"></TextField>
         <TextField error={inError.cognome} helperText={inError.cognome === true? "Il campo non può essere lasciato vuoto" : ""} onChange={(e) => setNewCognome(e.target.value)} style={{margin:'30px'}}  variant="outlined" label="Cognome"></TextField>
         <TextField onChange={(e) => setNewCitta(e.target.value)} style={{marginLeft:"30px", marginRight:"30px"}} variant="outlined" label="Citta"></TextField>
         <TextField onChange={(e) => setNewNumero(e.target.value)} style={{margin:"30px"}} variant="outlined" label="Numero"></TextField>
         <TextField error={inError.email} helperText={inError.email === true? "Il campo non può essere lasciato vuoto" : ""} onChange={(e) => setNewEmail(e.target.value)} style={{marginLeft:"30px", marginRight:"30px"}} variant="outlined" label="Email"></TextField>
        
         <TextField onChange={(e) => setNewLinkedin(e.target.value)} style={{margin:'30px'}} variant="outlined" label="Linkedin"></TextField>
         <TextField onChange={(e) => setNewPosizione(e.target.value)} style={{marginLeft:"30px", marginRight:"30px"}} variant="outlined" label="Posizione"></TextField>
         <div style={{ margin:"30px", borderStyle:"solid", borderWidth: "2px", padding:"10px", borderRadius:"5px", borderColor:"#d6d6d6" }}>
         <Typography style={{marginLeft: "5px", color:"#898989"}} >Foto</Typography>
         <img style={{marginLeft:"30px",maxWidth:"300px"}} src={files[0]?.thumbnail?.src} />
         <FormField
        key={1}
        fieldset={"images"}
        field={{label:"Carica immagine",name: 'images', doctype: 'image', type: 'files', accept: 'image/*'}}
        value={[]}
        files={[]}
        options={undefined}
        placeholder={""}
        error={undefined}
        disabled={false}
        onButtonClicked={onButtonClicked}
        onFilesSelected={onFilesSelected}
        onFileRemoved={onFileRemoved}
        setPrincipal={setPrincipal}
        onInputChange={onInputChange}
        setPosition={setPosition}
      />
</div>
         {uploading === true ?
          <LinearProgress color="success" /> : <></>}
        </DialogContent>
        <DialogActions>
    
          <Button onClick={() => setOpen(false)} >Annulla</Button>
          <Button variant="contained"
          onClick={async() => {      
            if(newName == "" )
            {
              
           inError.nome = true
            }else{
              inError.nome = false
            }

            if(newCognome == "" )
            {
              
              inError.cognome = true
            }else{
              inError.cognome = false
            }

            if(newEmail == "" )
            {
              
              inError.email = true
            }else{
              inError.email = false
            }
            
            
            
            if(Object.values(inError).indexOf(true) > -1)
            {

            }
            else {
            const refer={
              Id: 0,
               Nome: newName,
               Cognome: newCognome, 
               Citta: newCitta, 
               Numero: newNumero, 
               Email: newEmail,
                Linkedin: newLinkedin,
                 Posizione: newPosizione, 
                 Foto: newFoto, 
                 Old:false

            }
            setUploading(true);
           await  ads.setNewReferente(refer);
           setUploading(false);
           setOpen(false);
           const ciao = await ads.getReferenti();
           setReferenti(ciao);
           await onPublish()

           setNewName("");
           setNewCognome("");
           setNewEmail("");
           setNewCitta("");
           setNewNumero("");
           setNewLinkedin("");
           setNewPosizione("");
          }
          }}
          >Aggiungi</Button>
      
        </DialogActions>
      </Dialog>
    </React.Fragment>
    </TopNavBarLayout>
  

  );

 
}

export default Customers;
