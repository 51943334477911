import configuration from "../configs/config.json";
const {
  http: {
    jwt: { key, header },
  },
} = configuration;

const browserStorage = sessionStorage || localStorage;

export default {
  load: (headers) => {
    browserStorage.setItem(key.token, headers[header.token]);
    browserStorage.setItem(key.refresh_token, headers[header.refresh_token]);
  },
  unload: () => {
    browserStorage.removeItem(key.token);
    browserStorage.removeItem(key.refresh_token);
  },
  get: (refresh) => ({
    headers: {
      Authorization: refresh
        ? "Bearer " + browserStorage.getItem(key.refresh_token)
        : "Bearer " + browserStorage.getItem(key.token),
    },
  }),
};
