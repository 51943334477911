import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  CardHeader,
  CardBody,
  Card,
  Input,
  Button,
  Container,
  Row,
  Col,
  Label,
  FormText,
  Badge,
  Progress,
  FormGroup,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import HomeTour from "../api/hometour";
import Ads from "../api/ads";
import {
  roomDesc,
  adTypeList,
  typeList,
} from "../configs/hometour.config.json";
import { useLocation } from "react-router";
// import { DELETE_HOMETOUR } from '../store/actions/actions';

function HomeTourUploader({
  onHomeTourSelected,
  onDeleteHomeTour,
  tour360,
  progress,
}) {
  // const dispatch = useDispatch();
  const params = useLocation();
  const idFromUrl = params.pathname.split("/")[2];
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFileImg, setSelectedFileImg] = useState([]);
  const [fileDesc, setFileDesc] = useState([]);
  const [publishHomeTour, setPublishHomeTour] = useState(false);
  const [url, setUrl] = useState();
  const [imm, setImm] = useState();

  const dispatch = useDispatch();

  const [adType, setAdType] = useState("1");
  const [type, setType] = useState("1");

  const desc = useSelector((state) => state.forms.ad.real_estate.descrizione);
  //   const typeId = useSelector((state) => state.forms.ad.real_estate.tipologia);
  const roomsId = useSelector((state) => state.forms.ad.real_estate.locali);
  const rentPrice = useSelector((state) => state.forms.ad.amounts.pigione);
  const canton = useSelector((state) => state.forms.ad.real_estate.cantone);
  const city = useSelector((state) => state.forms.ad.real_estate.citta);
  const ref = useSelector((state) => state.forms.ad.real_estate.referente);
  const id = useSelector((state) => state.forms.ad.id);

  const onFileChange = (e) => {
    if (selectedFiles.length + e.target.files.length <= 8) {
      let temp = selectedFiles;
      setSelectedFiles(
        Array.prototype.slice
          .call(temp)
          .concat(Array.prototype.slice.call(e.target.files))
      );
    } else {
      alert("Massimo 8 immagini per oggetto");
    }
  };

  const setLink = (e) => {
    setUrl(e.target.value);
  };

  const handleActivateHomeTour = () => {
    setPublishHomeTour((prevState) => !prevState);
  };
  const handleDelete = (index) => {
    // console.log(index)
    setSelectedFiles(
      selectedFiles.filter((file, fileIndex) => {
        return fileIndex !== index;
      })
    );
    setFileDesc(
      fileDesc.filter((file, fileIndex) => {
        return fileIndex !== index;
      })
    );
  };

  // const deleteHomeTour = async (e) => {
  //     e.preventDefault();
  //     const formData = new FormData();
  //     formData.append(
  //         'object_id_del',
  //         tour360.tour360[0].externalReference || ''
  //     );
  //     formData.append('user_id', 73);
  //     formData.append('agency_id', 82);

  //     dispatch({ type: DELETE_HOMETOUR, payload: formData });
  // };

  const handleRoomDescChange = (e, index) => {
    const newValue = e.target.value;
    if (fileDesc[index]) {
      setFileDesc((prevState) => {
        let newState = [...prevState];
        newState[index] = newValue;
        return newState;
      });
    } else {
      setFileDesc((prevState) => [...prevState, newValue]);
    }
  };
  const handleSetAdType = (e) => {
    setAdType(e.target.value);
  };

  const handleSetType = (e) => {
    setType(e.target.value);
  };
  const setFormData = useCallback(async () => {
    if (selectedFiles.length > 0) {
      let formData = new FormData();
      const csfr = await axios.get("https://www.360home.ch/it/api/v1/csfr");
      const headers = {
        headers: {
          "X-CSRF-TOKEN": csfr.data.csfr,
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      };
      for (var i = 0; i < selectedFiles.length; i++) {
        formData.append("file[]", selectedFiles[i]);
        formData.append("position[]", i);
        formData.append(
          "room_description_id[]",
          fileDesc[i] ? fileDesc[i] : "1"
        );
      }

      //Hardcoded

      formData.append("description[0]", desc); //required
      formData.append("description[1]", "");
      formData.append("description[2]", "");
      formData.append("description[3]", "");
      formData.append("_token", csfr.data.csfr); //required
      formData.append("user_id", 82); //required
      formData.append("lang", "it"); //required
      formData.append("agency_id", "73"); //required
      formData.append("nation_id", "1"); //required
      formData.append("country_id", canton ? canton : ""); //required
      formData.append("currency", "1");
      formData.append("city_id", city ? city : ""); //required
      formData.append("type_id", type); //required
      formData.append("room_id", roomsId);
      formData.append("form_obj_enable", publishHomeTour ? "1" : "0");
      formData.append("price", rentPrice); //required
      formData.append("ad_type_id", adType); //required
      formData.append("reference_id", ref ? ref : "");

      onHomeTourSelected(formData, headers, type, true);
    }
  }, [
    selectedFiles,
    desc,
    canton,
    city,
    type,
    roomsId,
    publishHomeTour,
    rentPrice,
    adType,
    onHomeTourSelected,
    fileDesc,
    ref,
  ]);

  useEffect(() => {
    if (idFromUrl === "new") {
      setSelectedFiles([]);
      setSelectedFileImg([]);
      setFileDesc([]);
      setPublishHomeTour(false);
      setType("1");
      setAdType("1");
    }
  }, [idFromUrl]);

  useEffect(() => {
    if (selectedFiles.length > 0) {
      setFormData();
      setSelectedFileImg(
        selectedFiles.map((item, index) => {
          return URL.createObjectURL(item);
        })
      );
    } else {
      onHomeTourSelected(null, null, null, false);
    }
  }, [selectedFiles, setFormData, onHomeTourSelected]);

  const fileData = () => {
    if (selectedFiles.length > 0) {
      return [...selectedFiles].map((file, index) => {
        return (
          <Col key={file.name} xs={3}>
            <Card className="homeTourCard">
              <CardHeader>{file.name}</CardHeader>
              <CardBody>
                <div className="homeTourPreview">
                  <img alt="360image" src={selectedFileImg[index]} />
                </div>
                <p>Tipo File: {file.type}</p>
                <p>Ultima Modifica: {file.lastModifiedDate.toDateString()}</p>
                <Label for="room_description_id[]">Nome locale</Label>
                <Input
                  type="select"
                  name="room_description_id[]"
                  onChange={(e) => handleRoomDescChange(e, index)}
                  value={fileDesc[index]}
                >
                  {roomDesc.map((item, index) => {
                    return (
                      <option key={item.name} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </Input>
                <br />
                <Button onClick={() => handleDelete(index)} color="danger">
                  <i className="fa fa-times"></i> elimina
                </Button>
              </CardBody>
            </Card>
          </Col>
        );
      });
    } else {
      return (
        <Col>
          <FormText style={{ marginLeft: "5px" }} color="muted">
            Scegli i files da caricare.
          </FormText>
        </Col>
      );
    }
  };

  let progressBar = null;
  if (progress) {
    const percent = Math.round((progress.loaded / progress.total) * 100);
    progressBar = (
      <Col xs={12}>
        <Progress color="info" animated value={percent}>
          {percent}%
        </Progress>
      </Col>
    );
  }

  return (
    <>
      <Container id="homeTour" fluid>
        <Row className="homeTourFileInput">
          {tour360 &&
          tour360.tour360 &&
          tour360.tour360.filter((b) => b.old === false).length > 0 ? (
            <Col xs={12} className="mb-5">
              <legend className="mb-5">Tour 360°</legend>
              {tour360.tour360.map((item, index) => {
                console.log("item", item);
                if (item.old !== true) {
                  return (
                    <div key={item.id}>
                      <Badge
                        style={{ fontSize: "1em" }}
                        href={item.url}
                        target="_blank"
                        rel="noreferrer noopen"
                        color="dark"
                      >
                        {item.url}
                      </Badge>
                      {/* <Button color="warning">
                                            Attiva/Disattiva
                                        </Button> */}
                      <div className="mt-2">
                        <Button
                          style={{ marginLeft: "10px" }}
                          color="danger"
                          onClick={() => onDeleteHomeTour(item.id)}
                        >
                          Elimina Tour
                        </Button>
                      </div>
                    </div>
                  );
                }
              })}
            </Col>
          ) : (
            <>
              <Col xs={12}>
                <legend>Tour 360°</legend>
                {/* <CustomInput
                                    id="homeTourHandler"
                                    type="file"
                                    accept="image/jpeg"
                                    multiple
                                    label={'Scegli le immagini'}
                                    onChange={onFileChange}
                                /> */}
                {selectedFiles.length > 0 && (
                  <>
                    <FormGroup check>
                      <Label check>
                        <Input
                          checked={publishHomeTour}
                          onChange={handleActivateHomeTour}
                          type="checkbox"
                          defaultChecked
                        />{" "}
                        Attiva su 360home.ch
                      </Label>
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Tipologia d'inserzione
                        <Input
                          type="select"
                          onChange={(e) => handleSetAdType(e)}
                          value={adType}
                        >
                          {adTypeList.map((item, index) => {
                            return (
                              <option key={item.name} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </Input>
                      </Label>
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Tipologia dell'oggetto
                        <Input
                          type="select"
                          onChange={(e) => handleSetType(e)}
                          value={type}
                        >
                          {typeList.map((item, index) => {
                            return (
                              <option key={item.name} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </Input>
                      </Label>
                    </FormGroup>
                  </>
                )}
                <label htmlFor="homeTourHandler" className="btn btn-primary">
                  Carica files
                </label>
                <input
                  id="homeTourHandler"
                  type="file"
                  accept="image/jpeg"
                  multiple
                  onChange={onFileChange}
                  label={"Scegli le immagini"}
                  style={{ visibility: "hidden" }}
                ></input>
              </Col>
              {fileData()}
              {progressBar}
            </>
          )}
        </Row>
        <label style={{ marginTop: "20px" }}>oppure</label>
        <br />
        <label style={{ marginTop: "20px", marginRight: "20px" }}>
          inserisci il link:
        </label>
        <input
          style={{ marginRight: "20px" }}
          onChange={(e) => setUrl(e.target.value)}
        ></input>
        {url ? (
          <Button
            color="primary"
            onClick={async () => {
              await HomeTour.post({
                id: id,
                reference: "",
                url: url,
                tipologia: "",
              });
              setImm(await Ads.get(id));
            }}
          >
            Convalida link
          </Button>
        ) : (
          <></>
        )}
        {imm && imm.status == 200 ? (
          <i
            style={{ marginLeft: "20px", fontSize: "20px", color: "green" }}
            className="fa fa-check-circle"
          ></i>
        ) : (
          <></>
        )}

        {/* {selectedFiles.length > 0 ? (
                    <div className="text-right">
                        <Button color="primary" onClick={onFileUpload}>
                            Carica su 360home.ch
                        </Button>
                    </div>
                ) : null} */}
      </Container>
    </>
  );
}

export default HomeTourUploader;
