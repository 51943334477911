import React from 'react';
import ReactDom from 'react-dom'
import { Button } from 'reactstrap';

export default function Modal({ open, children, onClose }) {
    if (!open) return null;

    return ReactDom.createPortal(
        <>
            <div id="modalOverlay"></div>
            <div id="publishModal">
                {children}
                <br />
                <br />
                <Button color="primary" onClick={onClose}>Chiudi</Button>
            </div>
        </>,
        document.getElementById('modal-root')
    );
}
