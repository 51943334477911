const ThumbnailHelper = {
  createParameters: (file) => {
    switch (file.type) {
      case "image/apng":
      case "image/png":
      case "image/bmp":
      case "image/gif":
      case "image/jpg":
      case "image/jpeg":
        return {
          reader: true,
          onerror: {
            icon: "fa-file-image-o",
            className: "text-info",
          },
        };

      case "audio/wave":
      case "audio/wav":
      case "audio/x-wav":
        return {
          icon: "fa-file-audio-o",
          className: "text-info",
        };

      case "application/pdf":
        return {
          icon: "fa-file-pdf-o",
          className: "text-danger",
        };

      case "application/powerpoint":
        return {
          icon: "fa-file-powerpoint-o",
          className: "test-danger",
        };

      case "application/word":
        return {
          icon: "fa-file-word-o",
          className: "test-primary",
        };

      case "application/excel":
        return {
          icon: "fa-file-excel-o",
          className: "test-success",
        };

      case "application/zip":
        return {
          icon: "fa-file-zip-o",
          className: "test-light",
        };

      case "text/plain":
        return {
          icon: "fa-file-text-o",
          className: "text-secondary",
        };

      case "application/octet-stream":
      default:
        return {
          icon: "fa-file-o",
          className: "text-secondary",
        };
    }
  },
};

export default ThumbnailHelper;
