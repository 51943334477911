import React, { useEffect } from "react";
//import * as Sentry from "@sentry/react";
//import { Integrations } from "@sentry/tracing";
import { hotjar } from "react-hotjar";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "./pages/Login";
import UserProfile from "./pages/UserProfile";
import AdInsert from "./pages/AdInsert";
import InsertionList from "./pages/InsertionList";
import Customers from "./pages/Customers";
import Referenti from "./pages/Referenti";
import Promozioni from "./pages/Promozioni";
import Stabili from "./pages/Stabili";
import StabiliInsert from "./pages/StabiliInsert";
import StabiliModify from "./pages/StabiliModify";
import Stats from "./pages/Stats";
import "./App.css";
import NotFound from "./pages/404";

/*
Sentry.init({
  dsn:
    "https://2ff31d8a863b4debb771b1f7b6dcfb19@o503742.ingest.sentry.io/5589246",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control (range is 0..1)
  tracesSampleRate: 0.01,
});
*/
// Hotjar Tracking Code for http://apton.ch/
const config = {
  hijd: 2256168,
  hjsv: 6,
};
hotjar.initialize(config.hijd, config.hjsv);

function App(props) {
  useEffect(props.hideLoader, []);
  const user = useSelector((state) => state.user);
  return user ? (
    <Router>
      <Switch>
        <Route path="/404">
          <NotFound />
        </Route>
        <Route path="/profile">
          <UserProfile />
        </Route>
        {/* <Route path="/stabili">
          <Stabili />
        </Route> */}
        <Route path="/newstabile">
          <StabiliInsert />
        </Route>
        <Route path="/modstabile">
          <StabiliModify />
        </Route>
        <Route path="/ad/:id">
          <AdInsert />
        </Route>
        <Route exact path="/">
          <InsertionList />
        </Route>
        <Route path="/customers">
          <Customers />
        </Route>
        <Route path="/referenti">
          <Referenti />
        </Route>
        {/* <Route path="/promozioni">
          <Promozioni />
        </Route> */}
        <Route path="/stats">
          <Stats />
        </Route>
        <Redirect to="/" />
      </Switch>
    </Router>
  ) : (
    <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Redirect to="/login" />
      </Switch>
    </Router>
  );
}

export default App;
