export const ERROR_DISPLAY = "ERROR_DISPLAY";
export const ERROR_DISMISS = "ERROR_DISMISS";

export const VALIDATION_ERROR = "VALIDATION_ERROR";
export const VALIDATION_SUCCESS = "VALIDATION_SUCCESS";

export const error = e => async dispatch => {
  dispatch({
    type: ERROR_DISPLAY,
    payload: e
  });
};

export const dismissError = () => async dispatch => {
  dispatch({
    type: ERROR_DISMISS
  });
};

export const validationError = ({ form, fieldset, field, options }) => {
  const { value, min, max } = options || {};
  return {
    type: VALIDATION_ERROR,
    errors: {
      form,
      fieldset,
      field,
      value,
      min,
      max
    }
  };
};

export const validationSuccess = ({ form, fieldset, field }) => {
  return {
    type: VALIDATION_SUCCESS,
    errors: {
      form,
      fieldset,
      field
    }
  };
};
