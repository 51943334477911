import React, { useState, useEffect } from "react";
import TopNavBarLayout from "../layouts/TopNavBarLayout";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ads from "../api/ads";
import OutlinedInput from "@mui/material/OutlinedInput";
import ClearIcon from "@mui/icons-material/Clear";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import CheckIcon from "@mui/icons-material/Check";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fcfcfc",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  height: "50px",
}));

function UserProfile() {
  const [promozioni, setPromozioni] = useState();
  const [change, setChange] = useState({});

  const [modifica, setModifica] = useState([]);

  const [id, setId] = useState("");
  const [codice, setCodice] = useState("");
  const [nome, setNome] = useState("");
  const [indirizzo, setIndirizzo] = useState("");
  const [blocco, setBlocco] = useState("");
  const [regione, setRegione] = useState("");
  const [regioni, setRegioni] = useState([]);

  useEffect(() => {
    (async function () {
      try {
        const ciao = await ads.getStabili();
        setPromozioni(ciao);
        const reg = await ads.getRegioni();
        setRegioni(reg);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [promozioni]);

  const rows = promozioni?.data?.map((row) => {
    const modi = modifica.find((f) => f.ind === row.id && f.state === true);
    if (
      (row.codice.toLowerCase().includes(codice.toLowerCase()) ||
        codice === "") &&
      (row.nome.toLowerCase().includes(nome.toLowerCase()) || nome === "") &&
      (row.id.toString().toLowerCase().includes(id.toLowerCase()) ||
        id === "") &&
      (row.blocco.toLowerCase().includes(blocco.toLowerCase()) ||
        blocco === "") &&
      (row.indirizzo.toLowerCase().includes(indirizzo.toLowerCase()) ||
        indirizzo === "") &&
      (regioni?.data
        ?.find((i) => i.id === row?.regioneId)
        ?.nome.toLowerCase()
        .includes(regione) ||
        regione === "")
    ) {
      return (
        <StyledTableRow
          onClick={(e) => {
            switch (e.detail) {
              case 1:
                console.log("click");
                break;
              case 2:
                window.location.href = `http://localhost:3000/modstabile?id=${row.id}`;
                break;
              case 3:
                console.log("triple click");
                break;
              default:
                return;
            }
          }}
          hover
          key={row.id}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell component="th" scope="row">
            <Chip color="success" label={row.id}></Chip>
          </TableCell>
          <TableCell align="left">
            {row.codice === "" ? (
              <></>
            ) : (
              <Chip color="primary" label={row.codice}></Chip>
            )}
          </TableCell>
          <TableCell align="left">
            {row.visibilita === false ? (
              <></>
            ) : (
              <CheckIcon style={{ color: "green" }} />
            )}
          </TableCell>
          <TableCell align="left">
            {modi !== undefined ? (
              <TextField
                variant="standard"
                value={change.Nome}
                onChange={(e) => setChange({ ...change, Nome: e.target.value })}
              ></TextField>
            ) : (
              row.nome
            )}
          </TableCell>
          <TableCell align="left">
            {modi !== undefined ? (
              <TextField
                variant="standard"
                value={change.Indirizzo}
                onChange={(e) => setChange({ ...change, Nome: e.target.value })}
              ></TextField>
            ) : (
              row.indirizzo
            )}
          </TableCell>

          <TableCell align="left">
            {modi !== undefined ? (
              <TextField
                variant="standard"
                value={change.Blocco}
                onChange={(e) => setChange({ ...change, Nome: e.target.value })}
              ></TextField>
            ) : (
              row.blocco
            )}
          </TableCell>
          <TableCell align="left">
            {modi !== undefined ? (
              <TextField
                variant="standard"
                value={change.Regione}
                onChange={(e) => setChange({ ...change, Nome: e.target.value })}
              ></TextField>
            ) : (
              regioni?.data?.find((i) => i.id === row?.regioneId)?.nome
            )}
          </TableCell>
        </StyledTableRow>
      );
    }
  });

  return (
    <TopNavBarLayout>
      <div style={{ padding: "20px" }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <a href="/newstabile">
            <Button style={{ marginBottom: "50px" }} variant="contained">
              Nuovo stabile
            </Button>
          </a>
        </Grid>

        <TableContainer size="small" component={Paper}>
          <Typography style={{ margin: "15px" }} variant="h5">
            Lista stabili
          </Typography>
          <Typography style={{ margin: "15px" }} variant="h5">
            Filtri
          </Typography>
          <FormControl variant="outlined">
            <InputLabel
              style={{ padding: "5px" }}
              htmlFor="outlined-adornment-password"
            >
              Id
            </InputLabel>
            <OutlinedInput
              value={id}
              onChange={(e) => setId(e.target.value)}
              size="small"
              style={{ margin: "10px" }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setId("")}
                    style={{ color: "red" }}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Id"
              variant="outlined"
            ></OutlinedInput>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel
              style={{ padding: "5px" }}
              htmlFor="outlined-adornment-password"
            >
              Codice
            </InputLabel>
            <OutlinedInput
              value={codice}
              onChange={(e) => setCodice(e.target.value)}
              size="small"
              style={{ margin: "10px" }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setCodice("")}
                    style={{ color: "red" }}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Id"
              variant="outlined"
            ></OutlinedInput>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel
              style={{ padding: "5px" }}
              htmlFor="outlined-adornment-password"
            >
              Nome
            </InputLabel>
            <OutlinedInput
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              size="small"
              style={{ margin: "10px" }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setNome("")}
                    style={{ color: "red" }}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Id"
              variant="outlined"
            ></OutlinedInput>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel
              style={{ padding: "5px" }}
              htmlFor="outlined-adornment-password"
            >
              Indirizzo
            </InputLabel>
            <OutlinedInput
              value={indirizzo}
              onChange={(e) => setIndirizzo(e.target.value)}
              size="small"
              style={{ margin: "10px" }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setIndirizzo("")}
                    style={{ color: "red" }}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Id"
              variant="outlined"
            ></OutlinedInput>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel
              style={{ padding: "5px" }}
              htmlFor="outlined-adornment-password"
            >
              Blocco
            </InputLabel>
            <OutlinedInput
              value={blocco}
              onChange={(e) => setBlocco(e.target.value)}
              size="small"
              style={{ margin: "10px" }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setBlocco("")}
                    style={{ color: "red" }}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Id"
              variant="outlined"
            ></OutlinedInput>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel
              style={{ padding: "5px" }}
              htmlFor="outlined-adornment-password"
            >
              Regione
            </InputLabel>
            <OutlinedInput
              value={regione}
              onChange={(e) => setRegione(e.target.value)}
              size="small"
              style={{ margin: "10px" }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setRegione("")}
                    style={{ color: "red" }}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Id"
              variant="outlined"
            ></OutlinedInput>
          </FormControl>
          <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell align="left">Codice</TableCell>
                <TableCell align="left">Visbile</TableCell>
                <TableCell align="left">Nome</TableCell>
                <TableCell align="left">Indirizzo</TableCell>
                <TableCell align="left">Blocco</TableCell>
                <TableCell align="left">Regione</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </TableContainer>
      </div>
    </TopNavBarLayout>
  );
}

export default UserProfile;
