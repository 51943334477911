import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  Form,
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Container,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import moment from "moment";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FormField from "../components/FormField";
import CaratteristicheSection from "../components/CaratteristicheSection";
import VicinanzeSection from "../components/VicinanzeSection";
import PromozioniSection from "../components/PromozioniSection";
import TopNavBarLayout from "../layouts/TopNavBarLayout";
import ThumbnailHelper from "../helpers/ThumbnailHelper";
import Hash from "../helpers/Hash";
import * as actions from "../store/actions/actions";
import forms from "../configs/forms.config.json";
import Formatter from "../helpers/Formatter";
import { useLocation, useHistory } from "react-router";
import PublishOnPortals from "../components/PublishOnPortals";
import HomeTourUploader from "../components/HomeTourUploader";
import TextField from "@mui/material/TextField";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Ads from "../api/ads";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Resizer from "react-image-file-resizer";

const form = forms.ad;
const hides = forms.constraints.ad.filter((c) => c.action === "hide");
const sets = forms.constraints.ad.filter((c) => c.action === "set");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const hide = (row, target) => {
  const matches = hides.filter(
    (rule) =>
      rule.targets.includes(target) &&
      rule.when.values.includes(row[rule.when.source][rule.when.field])
  );
  return matches.length > 0;
};

const set = (row) => {
  const matches = sets.filter((rule) =>
    rule.when.values.includes(row[rule.when.source][rule.when.field])
  );
  return matches;
};

const AdInsert = () => {
  const dispatch = useDispatch();
  const params = useLocation();
  const history = useHistory();

  const [Comment, setComment] = React.useState("");
  const [ref, setRef] = React.useState("");
  const [idUrl, setIdUrl] = React.useState("");

  const handlesChange = (event) => {
    setRef(event.target.value);
  };

  const adIdFromURL = params.pathname.split("/")[2]; //Getting id from URL

  const [hometourData, setHometourData] = useState({
    formData: "",
    headers: "",
    tipologia: "",
    upload: false,
  });
  const [hometourProgress, setHometourProgress] = useState(null);
  const hometourServerProcessing =
    hometourProgress &&
    hometourProgress.total &&
    hometourProgress.total === hometourProgress.loaded;

  const portalsData = useSelector(
    (state) => state.forms.ad.portals_publishing.checked
  );
  const [portalsServerProcessing, setPortalsServerProcessing] = useState(false);
  const [img, setImg] = useState("");
  const [files, setFiles] = useState([]);
  const [lookups, setLookups] = useState({
    cantone: [],
    regione: [],
    citta: [],
    tipologia: [],
    stabile: [],
    locali: [],
    immobiliCaratteristiche: [],
  });
  const [editorState, setEditorState] = useState(null);

  const cantons = useSelector(
    (state) => state.forms.formDefaultValues.cantons || []
  );
  const regions = useSelector(
    (state) => state.forms.formDefaultValues.regions || []
  );
  const cities = useSelector(
    (state) => state.forms.formDefaultValues.cities || []
  );
  const buildingtypes = useSelector(
    (state) => state.forms.formDefaultValues.buildingtypes || []
  );
  const spaces = useSelector(
    (state) => state.forms.formDefaultValues.spaces || []
  );
  const features = useSelector(
    (state) => state.forms.formDefaultValues.features || []
  );
  const referents = useSelector(
    (state) => state.forms.formDefaultValues.referents || []
  );
  const vicinanze = useSelector(
    (state) => state.forms.formDefaultValues.vicinanze || []
  );
  const promozioni = useSelector(
    (state) => state.forms.formDefaultValues.promozioni || []
  );
  const alert = useSelector((state) => state.forms.reg || undefined);

  const immo = useSelector((state) => state.forms.ad);

  const [checked, setChecked] = useState(
    immo.id === null || adIdFromURL === "new"
      ? false
      : immo.real_estate?.disponibilita?.includes("1900-01-04T10:34:23")
  );

  const ad = useSelector((state) => state.forms.ad);

  const tour360 = useSelector((state) => state.forms.ad.tour360 || []);
  const images = useSelector((state) => state.forms.ad.images.images);
  const documents = useSelector((state) => state.forms.ad.documents.documents);

  const { getting, putting, posting, deleting, uploading } = useSelector(
    (state) => state.loading.ads
  );
  const errors = useSelector((state) => state.errors.ad);

  const onButtonClicked = (e, fieldset, field) => {
    const { name } = e.target;
    console.log(fieldset, field, name);
  };

  const onHomeTourSelected = useCallback(
    (formData, headers, tipologia, upload) => {
      setHometourData({ formData, headers, tipologia, upload });
    },
    []
  );

  const onInputChange = async (e, fieldset, field) => {
    let value = e;

    switch (field.type) {
      case "date":
        break;
      case "checkbox":
        value = e.target.checked;
        break;
      case "select":
      case "creatable-select":
        value = e && e.value;
        break;
      case "html-editor":
        setEditorState(value);
        value = draftToHtml(convertToRaw(value.getCurrentContent()));
        break;
      default:
        value = e.target.value;
        break;
    }
    if (e && e.__isNew__) {
      dispatch(actions.createAndChangeFieldValue("ad", fieldset, field, value));
    } else {
      dispatch(actions.changeFieldValue("ad", fieldset, field, value));
    }
    if (field.name === "tipologia") {
      const row = {
        tipologia: { id: field.name === "tipologia" ? value : null },
      };

      const matches = await set(row);
      if (matches !== undefined) {
        matches.forEach((match) => {
          match.targets.forEach((target) => {
            const otherFields = form[fieldset].fields.filter(
              (field) => field.name === target
            );
            if (otherFields.length > 0) {
              dispatch(
                actions.changeFieldValue(
                  "ad",
                  fieldset,
                  otherFields[0],
                  match.value
                )
              );
            }
          });
        });
      }
    }
  };

  const onDeleteHomeTour = async (url) => {
    const csfr = await axios.get("https://www.360home.ch/it/api/v1/csfr");
    const headers = {
      headers: {
        "X-CSRF-TOKEN": csfr.data.csfr,
      },
    };
    dispatch(actions.deleteHomeTour(headers, ad.id, history, url));
  };

  const onPublish = (e) => {
    e.preventDefault();

    if (adIdFromURL === "new") {
      files.map((f, index) =>
        Resizer.imageFileResizer(
          f,
          300,
          300,
          "JPEG",
          50,
          0,
          (uri) => {
            files[index].thumbnail = { src: uri };
          },
          "File",
          200,
          200
        )
      );

      dispatch(
        actions.formSubmitCreate({
          form: "ad",
          files,
          setFileProgress,
          history,
          hometourData,
          setHometourProgress,
          portalsData,
          setPortalsServerProcessing,
        })
      );
    } else {
      files.map((f, index) => {
        if (f.type === "image/jpeg") {
          Resizer.imageFileResizer(
            f,
            300,
            300,
            "JPEG",
            50,
            0,
            (uri) => {
              files[index].thumbnail = { src: uri };
            },
            "File",
            200,
            200
          );
        }
      });
      dispatch(
        actions.formSubmitUpdate({
          form: "ad",
          files,
          setFileProgress,
          history,
          hometourData,
          setHometourProgress,
          portalsData,
          setPortalsServerProcessing,
        })
      );
    }
  };

  const setFileProgress = (e, fieldset, field) => {
    setFiles((prevFiles) => {
      const index = prevFiles.findIndex(
        (file) =>
          file.fieldset === fieldset &&
          file.field === field &&
          file.name === e.name
      );
      if (index !== -1) {
        prevFiles[index].progress = e;
        return [...prevFiles];
      } else {
        return prevFiles;
      }
    });
  };

  const addFile = (file) => {
    setFiles((prevFiles) => {
      return [...prevFiles, file];
    });
  };

  const onFilesSelected = (e, fieldset, field) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      file.fieldset = fieldset;
      file.field = field;
      file.action = "upload";
      file.doctype = form[fieldset].fields.filter(
        (f) => f.name === field
      )[0].doctype;
      const params = ThumbnailHelper.createParameters(file);
      if (params.reader) {
        const reader = new FileReader();
        reader.onerror = () => {
          file.thumbnail = params.onerror;
          addFile(file);
        };
        reader.onload = () => {
          file.thumbnail = { src: reader.result };
          addFile(file);
        };
        reader.readAsDataURL(file);
      } else {
        file.thumbnail = params;
        addFile(file);
      }
    });
  };

  const ListReferents = referents.map((ref) => {
    return <MenuItem value={ref.id}>{ref.nome}</MenuItem>;
  });

  const onFileRemoved = (e, file) => {
    e.preventDefault();
    e.stopPropagation();
    setFiles((prevFiles) => {
      let others = prevFiles.filter(
        (f) =>
          f.action !== "upload" ||
          f.fieldset !== file.fieldset ||
          f.field !== file.field ||
          f.name !== file.name
      );
      others = others.map((f) => {
        return f.action === "none" &&
          f.fieldset === file.fieldset &&
          f.field === file.field &&
          f.name === file.name
          ? { ...f, action: "delete" }
          : f;
      });
      return others;
    });
  };

  const setPrincipal = (file) => {
    setFiles((prevFiles) => {
      const newFiles = prevFiles.map((f) => {
        f.principal = file.id === f.id;
        if (f.principal) {
          f.prevAction = f.action;
          if (f.action !== "upload") {
            f.action = "set";
          }
        } else {
          if (f.prevAction) {
            f.action = f.prevAction;
            delete f.prevAction;
          } else {
            f.action = "none";
          }
        }
        return f;
      });
      return newFiles;
    });
  };

  const setPosition = (file, num) => {
    setFiles((prevFiles) => {
      console.log("prev", prevFiles, file);
      const newFiles = prevFiles.map((f) => {
        if (f.id !== undefined) {
          if (file.id == f.id) {
            f.posizione = num;
          }
        } else {
          if (file.name == f.name) {
            f.posizione = num;
          }
        }

        return f;
      });
      return newFiles;
    });
  };

  const renderField = (fieldset, field) => {
    const row = { tipologia: { id: ad.real_estate.tipologia } };
    if (hide(row, field.name)) {
      return null;
    }

    let value = Formatter.display(
      ad[fieldset] && ad[fieldset][field.name],
      field.type,
      editorState
    );

    const error = errors && errors[fieldset] && errors[fieldset][field.name];
    const options =
      ["select", "creatable-select"].includes(field.type) && lookups[field.name]
        ? lookups[field.name].map((item) => ({
            value: item.id,
            label: item.nome || item.numero,
          }))
        : field.options;
    const placeholder = field.type === "select" ? "Seleziona..." : "";
    const key = `${fieldset}-${field.name}`;

    if (field.name === "disponibilita") {
      const now = new Date();
      var date = new Date("1900-01-04 10:34:23");

      field.label =
        value <= now ? (
          <>
            Disponibile da subito <small>(perché la data è nel passato)</small>
            <div>
              <FormControlLabel
                label="Su richiesta"
                control={
                  <Checkbox
                    checked={checked}
                    onChange={() => {
                      setChecked(!checked);
                      const val = checked === false ? date : now;
                      dispatch(
                        actions.changeFieldValue("ad", fieldset, field, val)
                      );
                    }}
                  />
                }
              />
            </div>
          </>
        ) : (
          <>
            Disponibile dal <small>(se la data è nel passato, da subito)</small>
            <div>
              <FormControlLabel
                label="Su richiesta"
                control={
                  <Checkbox
                    checked={checked}
                    onChange={() => {
                      setChecked(!checked);
                      const val = checked === false ? date : now;
                      dispatch(
                        actions.changeFieldValue("ad", fieldset, field, val)
                      );
                    }}
                  />
                }
              />
            </div>
          </>
        );
    }

    if (field.name === "pigione") {
      let results = form.real_estate.fields.filter(
        (f) => f.name === "contratto"
      );
      if (results.length > 0) {
        results = results[0].options.filter(
          (o) => o.value === ad.real_estate.contratto
        );
      }
      if (results.length > 0) {
        field.label = results[0].label;
      }
    }
    if (field.name === "disponibilita" && checked === true) {
      const now = new Date();
      var date = new Date("1900-01-04 10:34:23");
      return (
        <>
          Disponibile su richiesta
          <div>
            <FormControlLabel
              label="Su richiesta"
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => {
                    setChecked(!checked);
                    const val = checked === false ? date : now;
                    dispatch(
                      actions.changeFieldValue("ad", fieldset, field, val)
                    );
                  }}
                />
              }
            />
          </div>
          <div style={{ visibility: "hidden", height: "0px" }}>
            <FormField
              key={key}
              fieldset={fieldset}
              field={field}
              value={value}
              files={files.filter((file) => file.action !== "delete")}
              options={options}
              placeholder={placeholder}
              error={error}
              disabled={loading || uploading}
              onButtonClicked={onButtonClicked}
              onFilesSelected={onFilesSelected}
              onFileRemoved={onFileRemoved}
              setPrincipal={setPrincipal}
              onInputChange={onInputChange}
              setPosition={setPosition}
            />
          </div>
        </>
      );
    }
    return (
      <FormField
        key={key}
        fieldset={fieldset}
        field={field}
        value={value}
        files={files.filter((file) => file.action !== "delete")}
        options={options}
        placeholder={placeholder}
        error={error}
        disabled={loading || uploading}
        onButtonClicked={onButtonClicked}
        onFilesSelected={onFilesSelected}
        onFileRemoved={onFileRemoved}
        setPrincipal={setPrincipal}
        onInputChange={onInputChange}
        setPosition={setPosition}
      />
    );
  };

  const renderLoadingMessage = () => {
    switch (true) {
      case uploading:
        return "Upload in corso ...";
      case getting:
        return "Caricamento in corso ...";
      case putting:
        return "Salvataggio in corso ...";
      case posting:
        return "Creazione in corso ...";
      case deleting:
        return "Cancellazione in corso ...";
      case hometourServerProcessing:
        return "Elaborazione hometour in corso ...";
      case portalsServerProcessing:
        return "Pubblicazione sui portali esterni ...";
      default:
        return "Elaborazione in corso ...";
    }
  };

  useEffect(() => {
    (async function () {
      try {
        const stabili = await Ads.getStabili();

        setLookups({
          cantone: cantons,
          regione: regions,
          citta: cities,
          tipologia: buildingtypes,
          locali: spaces,
          immobiliCaratteristiche: features,
          referente: referents,
          stabile: stabili.data,
          vicinanze: vicinanze,
          promozioni: promozioni,
        });
      } catch (e) {
        console.error(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    //This is needed to avoid annoying warning, because we need this effect only on mount, we must use an empty array as 2nd arg.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    Hash.Lookups(
      cantons,
      regions,
      cities,
      buildingtypes,
      spaces,
      features,
      referents,
      vicinanze,
      promozioni
    ),
  ]);

  useEffect(() => {
    if (adIdFromURL === "new") {
      dispatch({ type: actions.CLEAR_FORM_DATA });
    } else {
      console.log("jj", history);
      dispatch({
        type: actions.FETCH_AD_BY_ID,
        payload: adIdFromURL,
        history,
      });
    }
    //This is needed to avoid annoying warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adIdFromURL]);

  const [ms, setMs] = useState();
  const [msRef, setMsRef] = useState();

  useEffect(() => {
    (async () => {
      setMs(await Ads.getMessage(ad.id));
    })();
  }, [ad.id]);

  const setFilesFrom = (images, documents) => {
    let all = [];
    if (images) {
      all = all.concat(
        images.map((file) => ({
          ...file,
          fieldset: "images",
          field: "images",
          name: file.fileName,
          action: "none",
          doctype: form.images.fields.filter((f) => f.name === "images")[0]
            .doctype,
        }))
      );
    }
    if (documents) {
      all = all.concat(
        documents.map((file) => ({
          ...file,
          fieldset: "documents",
          field: "documents",
          name: file.fileName,
          action: "none",
          doctype: form.documents.fields.filter(
            (f) => f.name === "documents"
          )[0].doctype,
        }))
      );
    }
    setFiles(all);
  };

  useEffect(() => {
    setFilesFrom(images, documents);
    const unlisten = history.listen((location, action) => {
      const id = location.pathname.split("/")[2];
      if (id === "new") {
        setFiles([]);
      } else {
        setFilesFrom(images, documents);
      }
    });
    return () => {
      unlisten();
    };
    //This is needed to avoid annoying warning, because we need this effect only on mount, we must use an empty array as 2nd arg.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Hash.FileArray(images), Hash.FileArray(documents)]);

  useEffect(() => {
    dispatch({ type: actions.FETCH_LOOKUPS });
  }, [dispatch]);

  const loading =
    cantons.length === 0 ||
    regions.length === 0 ||
    vicinanze.length === 0 ||
    buildingtypes.length === 0 ||
    spaces.length === 0 ||
    features.length === 0 ||
    getting ||
    putting ||
    posting ||
    uploading ||
    hometourServerProcessing ||
    portalsServerProcessing;

  const set = useCallback(async () => {
    const aw = await Ads.getMessageRef(ad.id);
    setMsg(aw.data);
  });

  useEffect(() => {
    if (msg === undefined) {
      set();
    }
  }, [msg, set]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [messagesReferents, input] = useState([]);
  var [msg, setMsg] = useState();

  const listContatti = ms?.data?.map((contatto, index) => {
    const m = msg?.data != undefined ? msg?.data : msg;
    const mes = m?.filter((m) => m.messaggioId === contatto.id);

    const referentsMessages = mes?.map((r) => {
      const ref = referents.find((i) => i.id === r.referenteId);
      return (
        <>
          <div style={{ fontWeight: "bold", display: "flex" }}>
            {ref !== undefined ? ref?.nome + " " + ref?.cognome : ""}

            <span style={{ marginLeft: "10px" }}>
              {messagesReferents !== undefined ? r.data.substring(0, 10) : ""}
            </span>
          </div>
          {messagesReferents !== undefined ? r.messaggio : ""}
        </>
      );
    });

    return (
      <>
        {" "}
        <legend>
          {contatto.data !== undefined
            ? contatto.data.substring(0, 10) + " " + contatto?.cliente?.nome
            : ""}
        </legend>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <div style={{ maxWidth: "45%" }}>
            {contatto?.cliente?.email}
            <br />
            <br />
            {contatto?.messaggio}
            <br />
            <br />
            {referentsMessages}
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Referente
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select"
                label="Referente"
                onChange={handlesChange}
                value={ref}
              >
                {ListReferents}
              </Select>
            </FormControl>
            <TextField
              value={Comment}
              onChange={(e) => setComment(e.target.value)}
              style={{ margin: "10px" }}
              multiline
              maxRows={4}
              id="outlined-basic"
              label="Commento"
              variant="outlined"
            />
            <Button
              onClick={() => {
                var today = moment().format("DD.MM.YYYY");
                var todays = moment().format("YYYY-DD-MMTHH:MM:SS");
                const id = contatto.id;
                const mssg = {
                  Messaggio: Comment,
                  ReferenteId: ref,
                  ImmobileId: immo.id,
                  Data: today,
                  MessaggioId: id,
                };

                dispatch(actions.setMessageReferent(mssg));
                const msssg = {
                  id: 0,
                  data: todays,
                  messaggio: Comment,
                  messaggioId: id,
                  referenteId: ref,
                  immobileId: immo.id,
                };
                setMsg([...msg, msssg]);

                setComment("");
                setRef(null);
              }}
              style={{ margin: "10px" }}
              variant="contained"
            >
              Aggiungi commento
            </Button>
          </div>
        </Grid>
      </>
    );
  });

  const commenti = msg
    ?.filter((m) => m.messaggioId == null)
    .map((r) => {
      const ref = referents.find((i) => i.id === r.referenteId);
      return (
        <>
          <div style={{ fontWeight: "bold", display: "flex" }}>
            {ref !== undefined ? ref?.nome + " " + ref?.cognome : ""}

            <span style={{ marginLeft: "10px" }}>
              {messagesReferents !== undefined ? r.data.substring(0, 10) : ""}
            </span>
          </div>
          {messagesReferents !== undefined ? r.messaggio : ""}
        </>
      );
    });

  return (
    <TopNavBarLayout>
      <Container fluid>
        {alert === "regione" ? (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert variant="filled" severity="error">
              Indirizzo non trovato! Ricontrollare e immettere i dati corretti
            </Alert>
          </Snackbar>
        ) : (
          <></>
        )}
        <Row>
          <Col md={{ offset: 1, size: 10 }} sm={12}>
            <LoadingOverlay
              active={loading}
              spinner
              text={renderLoadingMessage()}
            >
              <Card>
                <CardHeader>
                  <div className="d-inline-block">
                    <h5 className="title">
                      {adIdFromURL === "new"
                        ? "Pubblicazione annuncio"
                        : `Modifica di annuncio #${adIdFromURL}`}
                    </h5>
                  </div>
                  <div className="d-inline-block pull-right">
                    <Button color="primary" onClick={onPublish}>
                      {adIdFromURL === "new" ? "Pubblica" : "Salva modifiche"}
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form>
                    {Object.keys(form)
                      .filter((fieldset) => !["id"].includes(fieldset))
                      .map((fieldset) => {
                        if (fieldset === "tour360") {
                          return (
                            <HomeTourUploader
                              key={fieldset}
                              onHomeTourSelected={onHomeTourSelected}
                              onDeleteHomeTour={onDeleteHomeTour}
                              tour360={tour360}
                              progress={hometourProgress}
                            />
                          );
                        }
                        if (fieldset === "portals_publishing") {
                          return <PublishOnPortals key={fieldset} />;
                        }
                        if (fieldset === "features") {
                          return (
                            <CaratteristicheSection
                              key={fieldset}
                              legend={form[fieldset].label}
                            />
                          );
                        }
                        if (fieldset === "vicinanze") {
                          return (
                            <VicinanzeSection
                              key={fieldset}
                              legend={form[fieldset].label}
                            />
                          );
                        }
                        if (fieldset === "promozioni") {
                          return (
                            <PromozioniSection
                              key={fieldset}
                              legend={form[fieldset].label}
                            />
                          );
                        }
                        return (
                          <fieldset key={fieldset} className="mb-4">
                            <legend>{form[fieldset].label}</legend>
                            {form[fieldset].fields
                              ? form[fieldset].fields.map((field) =>
                                  renderField(fieldset, field)
                                )
                              : renderField(fieldset, form[fieldset])}
                          </fieldset>
                        );
                      })}
                    <div className="text-right">
                      <Button color="primary" onClick={onPublish}>
                        {adIdFromURL === "new" ? "Pubblica" : "Salva modifiche"}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              {ms !== undefined && ms.data !== undefined ? (
                <>
                  <Card style={{ marginTop: "20px" }}>
                    <CardHeader>
                      <h5 className="title">Messaggi</h5>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        <Tab label={`${ms?.data.length} Contatti`} />
                        <Tab
                          label={`${
                            msg?.filter((m) => m.messaggioId == null)?.length
                          } Commenti`}
                        />
                      </Tabs>
                    </CardHeader>
                    <TabPanel value={value} index={0}>
                      <CardBody style={{ padding: "20px" }}>
                        {listContatti}
                      </CardBody>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        style={{ marginTop: "20px", marginBottom: "20px" }}
                      >
                        <div>{commenti}</div>
                        <div>
                          <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-standard-label">
                              Referente
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select"
                              label="Referente"
                              onChange={handlesChange}
                              value={ref}
                            >
                              {ListReferents}
                            </Select>
                          </FormControl>
                          <TextField
                            value={Comment}
                            onChange={(e) => setComment(e.target.value)}
                            style={{ margin: "10px" }}
                            multiline
                            maxRows={4}
                            id="outlined-basic"
                            label="Commento"
                            variant="outlined"
                          />
                          <Button
                            onClick={() => {
                              var today = moment().format("DD.MM.YYYY");
                              var todays = moment().format(
                                "YYYY-DD-MMTHH:MM:SS"
                              );
                              const id = null;
                              const mssg = {
                                Messaggio: Comment,
                                ReferenteId: ref,
                                ImmobileId: immo.id,
                                Data: today,
                                MessaggioId: id,
                              };

                              dispatch(actions.setMessageReferent(mssg));
                              const msssg = {
                                id: 0,
                                data: todays,
                                messaggio: Comment,
                                messaggioId: id,
                                referenteId: ref,
                                immobileId: immo.id,
                              };
                              setMsg([...msg, msssg]);

                              setComment("");
                              setRef(null);
                            }}
                            style={{ margin: "10px" }}
                            variant="contained"
                          >
                            Aggiungi commento
                          </Button>
                        </div>
                      </Grid>
                    </TabPanel>
                  </Card>{" "}
                </>
              ) : (
                <></>
              )}
            </LoadingOverlay>
          </Col>
        </Row>
      </Container>
    </TopNavBarLayout>
  );
};

export default AdInsert;
