import _ from "lodash";
import queryString from "query-string";
import Security from "./security";
import config from "./config";
import HttpStatusCode from "./httpstatus";

/**
 * Check axios response for HTTP status 401
 * @param {Exception} error
 */
const unauthorized = (response) => {
  return response.status === HttpStatusCode.Unauthorized;
};

/**
 * Extract HTTP status from axios exception
 * @param {Exception} error
 */
const extractHttpStatus = (error) => {
  return error.response && error.response.status
    ? error.response.status
    : undefined;
};

/**
 * Tries to refresh token and return results, with error type
 */
const tryRefreshToken = async () => {
  try {
    const response = await Security.refresh_token();
    config.load(response.headers);
    return { error: false, status: HttpStatusCode.Ok };
  } catch (e) {
    e.error = true;
    e.status = extractHttpStatus(e);
    return e;
  }
};

/**
 * Tries to invoke an async call, return error when fails
 * @param {AsyncFunction} call
 * @param {string} endpoint
 * @param {object} params
 * @param {boolean} querystring
 * @param {object} callOptions
 */
export const catcher = async (
  call,
  endpoint,
  params,
  querystring,
  callOptions = {}
) => {
  try {
    const options = _.merge(callOptions, config.get(false));
    const response = querystring
      ? await call(
          endpoint +
            (endpoint.includes("?") ? "&" : "?") +
            queryString.stringify(params),
          options
        )
      : await call(endpoint, params, options);
    response.status = HttpStatusCode.Ok;
    return response;
  } catch (e) {
    e.error = true;
    e.status = extractHttpStatus(e);
    if (!unauthorized(e)) {
      console.error(e);
    }
    return e;
  }
};

/**
 * When a call fails with 401, tries to refresh token and redo one time.
 * @param {AsyncFunction} call
 * @param {string} endpoint
 * @param {object} params
 * @param {boolean} querystring
 * @param {object} callOptions
 */
const retry = async (call, endpoint, params, querystring, callOptions = {}) => {
  let response = await catcher(
    call,
    endpoint,
    params,
    querystring,
    callOptions
  );
  if (unauthorized(response)) {
    response = await tryRefreshToken();
    if (!response.error) {
      response = await catcher(
        call,
        endpoint,
        params,
        querystring,
        callOptions
      );
    }
  }
  return response;
};

export default retry;
