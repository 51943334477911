import React from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import TopNavBarLayout from "../layouts/TopNavBarLayout";

function UserProfile() {
  const names = {
    id: "Id",
    username: "Username",
    displayName: "Nome",
  };
  const user = useSelector((state) => state.user);
  return (
    <TopNavBarLayout>
      <Container className="py-4">
        {Object.keys(user).map((key) => (
          <Row>
            <Col sm="12" md={{ size: 2, offset: 1 }} className="text-right">
              <strong>{names[key]}:</strong>
            </Col>
            <Col sm="12" md={{ size: 4 }} className="text-left">
              {user[key]}
            </Col>
          </Row>
        ))}
      </Container>
    </TopNavBarLayout>
  );
}

export default UserProfile;
