const separator = ":";

const Hash = {
  FileArray: (files) => {
    return files.map((file) => file.name || file.fileName).join(separator);
  },

  Lookup: (entries) => {
    return entries.map((entry) => entry.id).join(separator);
  },

  Lookups: (...lookups) => {
    return lookups.map((lookup) => Hash.Lookup(lookup)).join(separator);
  },
};

export default Hash;
