import axios from "axios";
import retry from "./helpers";
import config from "./config";
import configuration from "../configs/config.json";

const { http } = configuration;

const client = axios.create({
  baseURL: `${http.baseUrl}/security`,
  headers: http.headers,
});

export default {
  authenticate: async (email, password) =>
    await retry(client.post, "/authenticate", {
      ...http.defaults,
      username: email,
      password,
    }),

  autosense: async () => await retry(client.post, "/autosense", http.defaults),

  refresh_token: async () =>
    await client.post("/refresh-token", http.defaults, config.get(true)),
};
