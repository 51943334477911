import ads from "../../api/ads";
export const CHANGE_FIELD_VALUE = "CHANGE_FIELD_VALUE";
export const CREATE_AND_CHANGE_FIELD_VALUE = "CREATE_AND_CHANGE_FIELD_VALUE";
export const CREATE_AND_CHANGE_FIELD_VALUE_SUCCESS =
  "CREATE_AND_CHANGE_FIELD_VALUE_SUCCESS";
export const CREATE_AND_CHANGE_FIELD_VALUE_ERROR =
  "CREATE_AND_CHANGE_FIELD_VALUE_ERROR";
export const VALIDATED_FIELD_VALUE = "VALIDATED_FIELD_VALUE";
export const FORM_SUBMIT_CREATE = "FORM_SUBMIT_CREATE";
export const FORM_SUBMIT_UPDATE = "FORM_SUBMIT_UPDATE";
export const UPLOAD_COMPLETE = "UPLOAD_COMPLETE";
export const CHANGE_SORT_ORDER = "CHANGE_SORT_ORDER";
export const CHANGE_PAGE = "CHANGE_PAGE";
export const CHANGE_PAGESIZE = "CHANGE_PAGESIZE";
export const SET_MESSAGE = "SET_MESSAGE";
export const GET_MESSAGE = "GET_MESSAGE";

export const changeFieldValue = (form, fieldset, field, value) => async (
  dispatch
) => {
  dispatch({
    type: CHANGE_FIELD_VALUE,
    payload: {
      form,
      fieldset,
      field,
      value,
    },
  });
};

export const createAndChangeFieldValue = (
  form,
  fieldset,
  field,
  value
) => async (dispatch) => {
  dispatch({
    type: CREATE_AND_CHANGE_FIELD_VALUE,
    payload: {
      form,
      fieldset,
      field,
      value,
    },
  });
};

export const validatedFieldValue = (form, fieldset, field, value) => async (
  dispatch
) => {
  dispatch({
    type: VALIDATED_FIELD_VALUE,
    payload: {
      form,
      fieldset,
      field,
      value,
    },
  });
};

export const formSubmitCreate = (data) => async (dispatch) => {


  dispatch({
    type: FORM_SUBMIT_CREATE,
    payload: data,
  });
};

export const formSubmitUpdate = (data) => async (dispatch) => {
  dispatch({
    type: FORM_SUBMIT_UPDATE,
    payload: data,
  });
};

export const uploadComplete = (form) => async (dispatch) => {
  dispatch({
    type: UPLOAD_COMPLETE,
    payload: { form },
  });
};

export const changeSortOrder = (form, field) => async (dispatch) => {
  dispatch({
    type: CHANGE_SORT_ORDER,
    payload: {
      form,
      field,
    },
  });
};

export const changePage = (form, page, size) => async (dispatch) => {
  dispatch({
    type: CHANGE_PAGE,
    payload: { form, page, size },
  });
};

export const changePageSize = (form, size) => async (dispatch) => {
  dispatch({
    type: CHANGE_PAGESIZE,
    payload: { form, size },
  });
};

export const deleteHomeTour = (headers, id, history, url) => async (dispatch) => {
  dispatch({
    type: DELETE_HOMETOUR,
    payload: { headers, id, history, url },
  });
};

export const setMessageReferent = (msg) => async (dispatch) => {
  dispatch({
    type: SET_MESSAGE,
    payload: { msg },
  });
};


export const fetchFeaturesFromApi = "FETCH_FEATURES_FROM_API";
export const featuresFetchedFromApi = "FEATURES_FETCHED_FROM_API";
export const addFeatureToFormList = "ADD_FEATURE_TO_LIST";
export const addVicinanzeToFormList = "ADD_VICINANZE_TO_LIST";
export const addPromozioniToFormList = "ADD_PROMOZIONI_TO_LIST";
export const removeFeatureFromList = "REMOVE_FEATURE_FROM_LIST";
export const removeVicinanzaFromList = "REMOVE_VICINANZA_FROM_LIST";
export const removePromozioneFromList = "REMOVE_PROMOZIONE_FROM_LIST";
export const FETCH_LOOKUPS = "FETCH_LOOKUPS";
export const FETCH_LOOKUPS_SUCCESS = "FETCH_LOOKUPS_SUCCESS";
export const FETCH_LOOKUPS_ERROR = "FETCH_LOOKUPS_ERROR";
export const FETCH_AD_BY_ID = "FETCH_AD_BY_ID";
export const FETCH_AD_BY_ID_SUCCESS = "FETCH_AD_BY_ID_SUCCESS";
export const CLEAR_FORM_DATA = "CLEAR_FORM_DATA";
export const PUBLISH_ON_PORTALS_VALUE_CHANGED =
  "PUBLISH_ON_PORTALS_VALUE_CHANGED";
export const POST_TO_HOMETOUR_FAILED = "POST_TO_HOMETOUR_FAILED";
export const DELETE_HOMETOUR = "DELETE_HOMETOUR";
export const DELETE_HOMETOUR_SUCCESS = "DELETE_HOMETOUR_SUCCESS";
export const DELETE_HOMETOUR_ERROR = "DELETE_HOMETOUR_ERROR";
